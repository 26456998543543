/**
 * File: styles.css
 * Description : Contains all necessary styling for the Bicycle Fix - Bicycle Repair, Maintenance and Tune-Ups Shop HTML5 Template
 * Author: xenioushk
 * URL: https://themeforest.net/user/xenioushk
 * Project: Bicycle Fix - Bicycle Repair, Maintenance and Tune-Ups Shop HTML5 Template
 * Version: 1.0.2
 * Last Update: 10-07-2018
 **/

/*========================================================================*/
/*   TABLE OF CONTENT
/*========================================================================*/
/*
/*      01. GENERAL STYLING 
/*      02. HEADER
/*      03. MAIN NAVIGATION MENU
/*      04. HOME PAGE
/*      05. BLOG LAYOUT
/*      06. BLOG SINGLE PAGE
/*      07. EVENTS/CAMPAIGN PAGE
/*      08. ABOUT US PAGE
/*      09. GALLERY PAGE
/*      10. SERVICE PAGE
/*      11. 404 PAGE
/*      12. FOOTER
/*      13. SIDEBAR & WIDGETS
/*      14. RESPONSIVE STYLING
/*
/*========================================================================*/

@import 'variables.less';
@import 'mixins.less';
@import 'reset.less';

/***********************************************************************************************/
/* 01. GENERAL STYLING  */
/***********************************************************************************************/

body {

    font: 18px/28px @body-font;
    color: lighten(@default-font-color, 10%);
    font-style: normal;
    font-weight: 400;
    position: relative;
}

img{
    max-width: 100%;
}

a {
    color: @primary-color;
    text-decoration: none;   
    .transition(all, 0.3s);
}

a:hover,
a:focus {

    color: darken(@primary-color, 20%);
    text-decoration: none;

}

blockquote{
    font: @body-font;
    color: @text-color;
}

h1, h2, h3, h4, h5, h6 {    
    font-family: @heading-font;
    font-style: normal;
    font-weight: 700;
    color: @heading-font-color;
    line-height: 36px;
    margin: 0 0 16px 0;
}


h1{

    font-size: 56px;

}

h2{

    font-size: 48px;

}

h2+p{

    margin-top: 12px;
}


h3{

    font-size: 36px;

}

h3+p{

    margin-top: 12px;
}


h4{

    font-size: 24px;

}

h4+p{

    margin-top: 12px;
}

h5{

    font-size: 20px;

}

h6{

    font-size: 16px;

}

* + p {
    margin-top: 12px;
}

p {
    font: 18px/28px @body-font;
    color: lighten(@default-font-color, 10%);
    font-style: normal;
    font-weight: 400;
    letter-spacing:0.01em;
    margin: 0 0 12px;
    padding: 0;
}

ul, li{

    list-style-type: none;
}

input[type=text],
input[type=password],
input[type=submit],
input[type=button],
textarea{

    .round(0);
    .drop-shadow(0, 0, 0, 0, 0);

    &:focus{

        .drop-shadow(0, 0, 0, 0, 0);

    }

}

strong{
    font-weight: 600;
}

// PRE LOADER

#preloader {
    background: rgba(255,255,255,1);
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 9999;
}
#preloader span {

    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    position: absolute;
    top: 50%;
}

// BACK TO TOP

#backTop{
    width:16px;
    height:16px;
    padding:10px;
    border-radius:0px;
    text-indent:-9999px;
    cursor:pointer;
    z-index:999999999;
    display:none;
    box-sizing:content-box;
    -webkit-box-sizing:content-box;
    opacity: 1;
    .transition(opacity, 0.2s);
    &:hover{
        opacity: 1;
    }
}

#backTop:after{
    position: absolute;
    content: '-';
    width: 16px;
    height: 16px;
    text-align: center;
    top: 0;
    left: 0;
    color: #FFF;
}

#backTop.custom{

    background-color: @primary-color;
    background-image: url('../images/uparr-48-b.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0px solid lighten(@primary-color, 20%);
    .transition( 0.2s );

    &:hover{
        background-color:  rgba(255,255,255,1);
        border: 0px solid darken(@primary-color, 20%);
    }
}

// COMMON UTILITY CLASSES

.text-bold{
    font-weight: bold;
}

.capitalize{
    text-transform: capitalize;
}

.uppercase{
    text-transform: uppercase;
}

.alert,
.progress,
.pre {
    .round(0px);
}

.margin-top-5{
    margin-top: 5px;
}

.margin-top-30{
    margin-top: 30px;
}

.margin-top-20{
    margin-top: 20px;
}

.margin-top-11{
    margin-top: 11px;
}

.margin-bottom-5{
    margin-bottom: 5px;
}

.margin-bottom-11{
    margin-bottom: 11px;
}

.margin-bottom-24{
    margin-bottom: 24px;
}

.margin-bottom-80{
    margin-bottom: 80px;
}

.margin-right-0{
    margin-right: 0px;
}

.padding-left-0{
    padding-left: 0px;
}

.padding-right-0{
    padding-right: 0px;
}

.padding-right-5{
    padding-right: 5px;
}

.padding-right-11{
    padding-right: 11px;
}

.no-padding{
    padding: 0px !important;
}


.inline {
    li {
        display: inline-block;
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
}


.narrow-p {
    margin: 0 auto 1.5em;

    width: 30%;
}

.fr { float: right;}
.fl { float: left;}
.cb { clear: both;}
.db{display: block;}

.overflow-hidden{
    overflow: hidden;
}

span.border-light-dotted{
    border-bottom: 1px dotted @primary-color;
}

span.border-bold-dashed{
    border-bottom: 2px dashed @primary-color;
}

// BLOCKQUOTE

blockquote {
    padding: 12px 26px 26px 42px;
    margin: 0 0 20px;
    font-size: 14px;
    font-family: @body-font;
    background: @pure-white-bg;
    position: relative;
    border: 1px solid @light-border-color;
    border-left: 4px solid darken(@light-border-color, 50%);
    .transition(0.3s);

    &:hover{

        border: 1px solid darken(@light-border-color,15%);
        border-left: 4px solid darken(@light-border-color, 50%);

    }

    p {

        font-size: 14px;
        line-height: 24px;


    }

}
blockquote:before {
    display: block;
    position: absolute;
    left: 12px;
    top: 14px;
    content: "\f10d";
    font-family: 'FontAwesome';
    font-size: 15px;
    color: #d6d6d6;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
blockquote.testimonial {
    background: @pure-white-bg;
    border-top: 1px solid @light-border-color;
    border-right: 1px solid @light-border-color;
    border-bottom: 1px solid @light-border-color;
    margin-bottom: 0;
}
cite {
    text-align: right;
    display: block;
}
cite:before { 
    margin-right: 4px;
}
.bq-author {
    padding: 16px 0 0 110px;
    position: relative;
}
.bq-author:before {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 69px;
    width: 0;
    height: 0;
    border-left: 22px solid transparent;
    border-right: 0 solid transparent;
    border-top: 22px solid #dcdcdc;
    z-index: 2;
}
.bq-author:after {
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    left: 70px;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 0 solid transparent;
    border-top: 20px solid #fff;
    z-index: 3;
}
.bq-author h6 {
    margin-bottom: 6px;
}
.bq-author .bq-author-info {
    font-size: 12px;
}

// ORDERED/UNORDERED LISTS

ol {

    li {
        padding: 0;
        margin: 0 0 10px 25px;
        list-style-type: decimal;
    }
}


.section-container{
    margin: 24px 0 0 0;
}

.section-light-heading{
    font-family: @heading-font;
    font-size: 85px;
    font-weight: 700;
    left: 0;
    letter-spacing: 0.15em;
    position: absolute;
    text-transform: uppercase;
    width: 100%;
    color:@separator-border;
    opacity:0.06;
    
}

.section-heading{

    position: relative;
    font-size: 42px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: @heading-font;
    letter-spacing: 0.03em;
    font-weight: 600;
    color: @heading-font-color;
    span{
        font-style: 400;
        color: @heading-font-color;
    }
    
    &:before {
        position: absolute;
        content: '';
        width: 32px;
        height: 32px;
        display: block;
        top: 46px;
        left: 50%;
        margin-left: -16px;
        background-image: url("../images/seperator.png");
        background-repeat: no-repeat;
        background-position: center center;
      }

    

}

.section-heading-alt{
    color: @light-text-color;
    
    &:before {
        position: absolute;
        content: '';
        width: 32px;
        height: 32px;
        display: block;
        top: 46px;
        left: 50%;
        margin-left: -16px;
        background-image: url("../images/seperator-alt.png");
        background-repeat: no-repeat;
        background-position: center center;
      }

    span{
        font-style: 400;
        color: @light-text-color;
    }
}

.section-subheading{
    font-size: 18px;
    font-weight: 400;
    font-family: @heading-font;
    color: lighten( @heading-font-color, 10%);
    margin-bottom: 24px;
    padding: 15px 0 0;
    margin-top: 32px;
}

.section-subheading-alt{
    color: @light-text-color;
}

// ACCORDION

.accordion .panel{
    margin-bottom: 3px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.accordion .panel .panel-title a{
    display: block;
}

span.label {
    margin-right: 1px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    font-size: 100%;
}

// BUTTONS

#btn,
.btn {
    text-align: center;
    .transition(0.3s);

    &:hover{

        color: #000000;

    }

}


.btn-dynamism{
    .round(0px);
    padding: 12px 42px;  

    &:hover{
        background: @primary-color;
        color: @light-text-color;
    }

}


.btn-custom{
    text-transform: uppercase;
    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 12px 14px;

    &:hover{
        border-color: darken(@light-border-color,15%);
        color: @primary-color;
        background: @pure-white-bg;
    }

}

.btn-custom-inverse{

    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    color: @primary-color;
    background: @pure-white-bg;
    .round();
    padding: 12px 14px;

    &:hover{

        background: @primary-color;
        color: @light-text-color;
    }

}

a.btn-load-more{
    margin-bottom: -36px;
    margin-top: 48px;
    .round(0px);
    background: transparent;
    color: @primary-color;
    background: @pure-white-bg;
    border: 1px solid @primary-color;
    padding: 12px 24px;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    .transition(0.3s);

    &:hover{

        color: @light-text-color;
        background: @primary-color;
        border: 1px solid @primary-color;
    }
}


a.btn-about {
    font-size: 16px;
    margin-top: 16px;
    background: @primary-color;
    border:2px solid @primary-color;
    color: @light-text-color;
    height: 42px;
    line-height: 24px;
    padding-left: 12px;
    padding-right: 12px;
    letter-spacing: 0.03em;
    font-weight: 600;
    text-align: left;
    .round(0px);

    &:hover{
        background: darken(@primary-color, 10%);
        border:2px solid @primary-color;
        color: @light-text-color;
    }

}


.button{
    display: inline-block;
    .round();
    color:white;
    font-weight: bold;	
    height: 3em;
    line-height: 1em;
    padding: 1em;

}

.small-button{
    display: inline-block;
    .round(0px);
    color:@dark-color;
    font-weight: bold;	
    height: 0;
    line-height: 0;
    padding: 1em 1em;
    margin: 6px 0;
    width: 100%;

}

// Text highlighter.

.text-highlighter{
    color: @primary-color;
}

.text-highlighter-white{
    color: @light-text-color;
}

#comment_btn{

    .round(0px);
    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    .drop-shadow(0, 1px, 1px, 0, 0.1);
    background: transparent;
    color: @text-color;
    padding: 6px 12px;

    &:hover{
        border: 1px solid @primary-color;
        color: @primary-color;
        background: transparent;
    }

}

// ERROR NOTIFICATION

#contact-form .p-errors label{
    color: lightcoral;
}

#contact-form .p-errors input[type="text"],
#contact-form .p-errors input[type="email"],
#contact-form .p-errors textarea{
    border-color: lightcoral;
}

// WordPress Theme Support

.wp-caption {
    text-align: center;
}

.wp-caption .wp-caption-text {
    margin-bottom: 0px;
    font-size: 12px;
    font-style: italic;
    line-height: 34px;
}

.sticky{   
    margin-bottom: 21px;
    background: #FFFFFF;    
}

/*----- SECTION OVERLAY  ----*/

.section-theme-bg-overlay{
    position: relative;
    clear: both;
    
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(57,105,247,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }
}

.section-white-bg-overlay{
    position: relative;
    clear: both;
    
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255,255,255,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }
}

.section-black-bg-overlay{
    position: relative;
    clear: both;
    
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(83,83,83,0.9);
        content: '';
        width: 100%;
        height: 2000px;
    }
}

/*------------------------------ Gallery Caption  ---------------------------------*/

.gallery-caption{}

/*------------------------------ By Post Author ---------------------------------*/

.bypostauthor {}




/***********************************************************************************************/
/* 02. HEADER */
/***********************************************************************************************/
 
.main-header {
    height: auto;
    width: 100%;
    
    .sticky-wrapper{
        background: rgba(0,0,0,0.4);
    }
    
    .navgiation-wrapper{
        background: @pure-white-bg;
    }

    .top-bar {

        background: rgba(0,0,0,0.7);
        line-height:  38px;
        color: @light-text-color;
        font-size: 14px;

        i{
            color: @primary-color;
            margin-right: 5px;
            display: inline-block;
        }

        a{
            color: @light-text-color;
        }

    }

    .top-phone-no{
        display: inline-block;
        margin-right: 12px;
    }

    .top-email-info{
        display: inline-block;
    }

    .top-appointment-btn{
        background: @primary-color;
        color:@light-text-color;
        padding: 14px 20px;
        font-size: 18px;
        line-height: 32px;
        border: 0px solid #FFF;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top:0px;
        float:right;
        .round(0px);

        i.fa{
            color:@light-text-color;
        }

        &:hover{
            background: darken(@primary-color, 10%);
        }
    }

    .top-location-info{
        margin-right: 12px;
    }
}

    
// MAIN HEADER

.main-top-header{
  background:@top-bar-bg;
  color:@light-text-color;
  line-height:38px;
  
  p{
    color:@light-text-color;
    font-size: 16px;
    letter-spacing: 0.03em;
    line-height: 42px;
    margin-bottom: 0;
    padding-bottom: 0;
    }
  
.top-bar-social {
    position:relative;
    overflow:hidden;
    
     a {
        display: inline-block;
        transition: all 0.3s ease-out 0s;
        border-radius: 0;
        font-size: 14px;
        margin-right:16px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        transform:rotate(45deg);
        background:@pure-white-bg;
        width: 24px;       
            .round(4px); 
        
         i.fa {
            color:@text-color;
            transform:rotate(-45deg);
        }
    
    &:hover{
        i.fa {
            color: @primary-color;
       }
     }
      
   }
   
  }

 } 


.transparent-header {
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
  
    .sticky-wrapper{
        background: transparent !important;
    }
    
}

// SITE LOGO

.logo {
    margin-top: 32px;
    margin-bottom: 0;
}

.header-container{
    border-bottom: 0px solid @gray-color;
    padding-bottom: 0px; //24px
    box-shadow: 0px 0px 2px -4px rgba(0, 0, 0, 0.2);
    .transition(all, 0.2s);
}


// Header

.bcfix-header-top {
    
    padding-bottom: 24px;
    
    .bcfix-col-logo {
        position: relative;
        .bcfix-logo {
            line-height: 100px;
            img {
            vertical-align: middle !important;
            }
            
            a{
                display: block;
                position: relative;
                top: 6px;
            }
        }
    }
    
    .repair-icon-box {
        line-height: 19px;
        margin-top: 15px;
        padding-left: 51px;
        position: relative;
        margin-top: 32px;
        float:right;
        
        .icon-container {
            color: @light-text-color;
            background:@secondary-color;
            transform:rotate(45deg);
            font-size: 20px;
            left: -12px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            position: absolute;
            top: 8px;
            border: @primary-color;;
            text-align: center;
            .round(4px);
            
            i{
                transform:rotate(-45deg);
                display:inline-block;
           }
        }
        
        .text {
          font-size: 16px;
          letter-spacing: .03px;
            .head-heading{
                padding-top: 4px;
                font-size: 16px;
                line-height: 24px;
                text-transform:uppercase;
                letter-spacing: 0.03em;
                display: block;                
                display: block;
                color: @text-color;
            }
            .head-content{
                font-size: 18px;
                font-weight:400;
                letter-spacing: 0.05em;
                line-height:24px;
                color: @dark-text-color;
            }
       }
   }
    
    .btn-quote {
        float: right;
        margin-top: 32px;
          .btn-request_quote{
                font-weight: 600;
                text-align: center;
                border: 2px solid @primary-color;
                color: @primary-color;
                .round(3px);
                padding: 12px 14px;

                &:hover{
                    background: darken(@primary-color, 10%);
                    color: @light-text-color;
                }

            }
        }
    
}


/***********************************************************************************************/
/* 03. MAIN NAVIGATION MENU */
/***********************************************************************************************/


a.logo {
    margin-top: 10px;
    display: inline-block;
    
    img{
        display: block;
    }
}

.navbar-default {
    border: none;
    background: @text-color;
    margin-bottom: 0px;
    border-radius: 0;

    .navbar-collapse{
        padding: 0px;
    }
    
    .navbar-nav > li {
    }
    .navbar-nav > li > a {
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        padding: 20px 25px;
        text-transform: uppercase;
    }    
    
    .navbar-nav > li > a.link-active {
        background: @primary-color;
    }
    
    .navbar-nav > li > a:hover {
        color: #EEEEEE;
        background: @primary-color;
    }
       
    .navbar-nav > li > a:focus {
        color: #EEEEEE;
        background: @primary-color;
    }
    
    .navbar-nav li.drop {
        position: relative;
    }
    .navbar-nav li ul.drop-down {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 60px;
        left: 0;
        width: 190px;
        visibility: hidden;
        opacity: 0;
        z-index: 3;
        text-align: left;
        .transition(all, 0.2s);
    }
    .navbar-nav li ul.drop-down li {
        list-style: none;
        display: block;
        margin: 0;
    }
    .navbar-nav li ul.drop-down li ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform: rotateX(-90deg);
        -moz-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
        top: 0px;
        left: 100%;
        border-bottom: none;
    }
    .navbar-nav li ul.drop-down li a {
        display: inline-block;
        text-decoration: none;
        display: block;
        color: @light-text-color;
        font-size: 15px;
        padding: 12px 20px;
        text-transform: uppercase;
        font-weight: 500;
        background: rgba(0,0,0,0.6);
        margin: 0;
        border: none;
    }
    .navbar-nav li ul.drop-down li a:hover {

        background: rgba(0,0,0,0.8);
        color: @light-text-color;
    }
    .navbar-nav li ul.drop-down li:hover ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
    .navbar-nav li ul.drop-down li:last-child {
        border-bottom: none;
    }
    .navbar-nav li:hover > ul.drop-down {
        visibility: visible;
        opacity: 1;
    }
    
}

header.one-page .navbar-nav > li {
    margin-left: 0;
}
header.one-page .navbar-nav > li > a span {
    color:@primary-color;
}


// STICKY TOP MENU

.stuck {
    position:fixed;
    top:0;    
    left: 0px;
    right: 0px;
    z-index: 99;
    opacity: 1;
    .transition(opacity, 0.2s);
    background: rgba(0,0,0,0.8);
    width: 100%;
    padding: 0px;
    margin: 0 !important;
}

// Navbar Social icon

.navbar-contact{
    
    a.contact-support{
       color:@light-text-color;
       display:inline-block;
       padding-top:15px;
     }
     
}

/***********************************************************************************************/
/* 04. HOME PAGE */
/***********************************************************************************************/


// SECTION CONTENT BLOCK.

.section-content-block{
    padding: 80px 0 80px 0;
    background: @pure-white-bg;
}

.section-pure-white-bg{
    background: @pure-white-bg !important;
}

.section-secondary-bg{
    background: @second-bg-color !important;
}

.section-heading-wrapper{
    margin-bottom: 24px;
}

.home-page-section-heading{

    span{
        font-size: 16px;
        font-weight: normal;
        font-style: italic;
        color: lighten(@dark-text-color, 30%);
    }

}

.home-page-section-sub-heading{
    font-size: 26px;
    line-height: 32px;
    font-style: italic;
    color: lighten(@dark-text-color, 40%);
}


/***********************************************************************************************/
/*  HOME PAGE 01 */
/***********************************************************************************************/

// SLIDER 1

.slider-wrap{
    clear: both;
}

#slider_1{

    text-align: center;

    .item img {
        display: block;
        width: 100%;
        height: auto;
    }

    .slider_item_container{
        position: relative;
        text-align: center;
        width: 100%;
        min-height: 800px;
        background-repeat: no-repeat;
        background-size: cover;
        background: #000000;
      }

    .slider-content {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        width: 100%;
        
        
        .slider-bg {
            background:rgba(0,0,0,0.7);
            padding: 36px 36px;
            display:inline-block
        }  

        h2 {
            letter-spacing: 0.03em;
            position: relative;
            font-family: @slider-font;
            font-size: 60px;
            line-height: 70px;
            font-weight: 700;
            padding-bottom:20px;
            margin-bottom:20px;
            color: @light-text-color;
            text-transform:uppercase;

            &:after{
                position:absolute;
                content:"";
                background:@primary-color;
                width:100px;
                height:2px;
                left: 2px;
                bottom: -3px;
            }

            span{
                padding: 0 3px;
                font-weight:400;

            }

        }



        h3 {
            font-family: @slider-font;
            color: @light-text-color;
            font-size: 20px;
            font-weight: 400;
            line-height: 32px;
        }
        
    
         a.banner-video-icon {
            background: rgba(0, 0, 0, 0.5);
            border: 3px solid #f2f2f2;
            border-radius: 100%;
            color: @primary-color;
            display: block;
            width: 90px;     
            height: 90px;
            font-size: 42px;
            line-height: 82px;
            margin: 0 auto 20px;
            text-align: center;  
        
            >i{
               margin-left:12px; 
            }
            
            &:hover{
            background: rgba(0, 0, 0, 0.8);
            border: 3px solid @primary-color;
            color: #e6e6e6;
                
            }
        }
        
        
        .content-left{
            text-align: left;
        }
        
        .content-center{
            text-align: center;
            
            h2 {
                position: relative;

                &:after{
                    left:50%;
                    margin-left: -50px;
                }

            }
            
        }
        
        .content-right{
            text-align: right;
            
            h2 {
                position: relative;

                &:after{
                    left: initial;
                    right:0;
                    margin-left: -50px;
                }

            }
            
        }
        
        p{
          letter-spacing:0.03em;
          fon-size:20px;
          color:@text-color;
          line-height: 30px;
        }
        
        .slider-button{
         display:block;
         margin-top:40px;
         
        a.btn-slider {
            font-size: 20px;
            display: inline-block;
            background: rgba(0,0,0,0.3);
            border:2px solid @primary-color;
            color: @light-text-color;
            line-height: 32px;
            padding: 12px 24px;
            text-transform:uppercase;
            font-weight: 600;
            text-align: left;
            letter-spacing: 0.03em;
            .round(0px);

            &:hover{
                background: @primary-color;
                border:2px solid @primary-color;
                color: @light-text-color;
            }

        } 
        
        a.btn-slider-2 {            
            font-size: 20px;
            display: inline-block;
            margin-left: 20px;
            background: @pure-white-bg;
            border:2px solid @light-text-color;
            color: @primary-color;
            line-height: 32px;
            padding: 12px 24px;
            text-transform:uppercase;
            font-weight: 600;
            text-align: left;
            letter-spacing: 0.03em;
            .round(0px);            
            
            &:hover {
                background: darken( @pure-white-bg ,10% );
                border: 2px solid @light-text-color;
            }            
            
        } 
        
    }
        
       
        
 }


    .owl-controls{

        opacity: 0;
        -webkit-transition: all 0.3s ease-in-out ;
        -moz-transition: all 0.3s ease-in-out ;
        transition: all 0.3s ease-in-out ;

        .owl-nav div {
            color: #000000;
            font-size: 22px;
            line-height: 42px;
            margin-top: 0px;
            opacity: 1;
            position: absolute;
            top: 50%;
            margin-top: -32px;
            z-index: 1;
            -webkit-transition: all 0.3s ease-in-out ;
            -moz-transition: all 0.3s ease-in-out ;
            transition: all 0.3s ease-in-out ;

            &:hover{
                opacity: 0.8;
            }
        }

        .owl-nav div.owl-prev {

            .transition(all, 0.5s);
            left: 6px;
            width:60px;
            height: 60px;
            font-size: 36px;
            line-height: 60px;
            background: rgba(0,0,0,0.7);
            color: @primary-color;
        }

        .owl-nav div.owl-next {

           .transition(all, 0.5s);
            right: 6px;
            width:60px;
            height: 60px;
            font-size: 36px;
            line-height: 60px;
            background: rgba(0,0,0,0.7);
            color: @primary-color;
        }

    }

    &:hover{

        .owl-controls{
            opacity: 1;

            .owl-nav div.owl-prev {
                //left: 12px;
            }

            .owl-nav div.owl-next {
               // right: 12px;
            }

        }

    }

}


// ABOUT US

.section-about-us{

    clear: both;

}

.about-carousel{
    clear: both;
    .about-img{ 
        position:relative;
        overflow:hidden;
        img{
            vertical-align:middle;
        }

        a{
            background: rgba(0,0,0,0.5);
            border: 2px solid darken(@pure-white-bg, 10%);
            border-radius: 100%;
            color: @primary-color;
            font-size: 24px;
            left: 50%;
            line-height: 36px;    
            width: 62px;
            height: 62px;       
            padding: 10px;
            position: absolute;
            z-index:3;
            text-align: center;
            top: 50%;
            margin-left: -50px;
            margin-top: -50px;

            >i{
                margin-left:8px; 
            }

            &:hover{
                background: rgba(0,0,0,0.8);
                border: 2px solid @primary-color;
                color: darken(@pure-white-bg, 10%);

            }

        }        


    }


    .owl-controls{
        display:block;
        position: relative;
        margin-top:0px;
        opacity:0.5;
        .transition( 0.3s);

        .owl-nav{
            bottom: 6px;
            display: inline-block;
            position: absolute;
            right: 6px;
            .owl-prev{
                background: @primary-color;
                color:@light-text-color;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                text-align: center;
                width: 40px;
                margin-right:5px;                
            }
            .owl-next{
                background: @primary-color;
                color:@light-text-color;
                display: inline-block;
                height: 40px;
                line-height: 40px;
                text-align: center;
                width: 40px;               
            }

        }


    }   


    &:hover{

        .owl-controls{
            opacity:0.9;
        }

    }

}

.about-details{
    
    h2{
        line-height: 48px;
        font-size: 38px;
    }

    .bold-text{
        font-weight:600;
        font-size: 22px;
    }

}


.about-highlight{  

    .transition( 0.5s);
    background: @pure-white-bg;
    border:1px solid @border-color;
    padding: 16px 12px 16px 12px;
    margin-top:15px;
    margin-bottom:15px;
    i.fa{
        margin: 0px 12px 0 0;
        float:left;
        display:inline-block;  
        opacity: 1;
        color: @primary-color;
        font-size: 32px;
    }

    h3{
        font-size:18px;
        font-family:@heading-font;
        font-weight:500;
        letter-spacing:0.03em;
        margin-bottom:0;
        text-transform:uppercase;
        color:@text-color;
    }

    p.info{
        font-size: 16px;
        margin: 6px 0 6px 0;
    }

    &:hover{

        background: @light-bg;

        i.fa{
            color:@text-color;
            transform:rotateY(360deg);
            .transition( 0.5s);
        }
    }

}

.about-team-img{
    transform: translateY(7px);
}


// OUR SERVICE

.section-how-we-work{
    position: relative;
    overflow: hidden;
}

.service-block-1{
    background: @pure-white-bg;
    border: 1px solid @border-color;
    position: relative;
    margin: 0px 0 24px 50px;
    padding: 24px 10px 24px 55px;

    span{
        left: -30px;
        color:@light-text-color;
        position: absolute;
        top: 50%;
        margin-top:-30px;
        width:60px;
        line-height:60px;
        height:60px;
        background:@primary-color;
        text-align:center;
        transform:rotate(45deg);
        .transition(all, 0.9s);            
        .round(4px);

        i.icon{
            font-size: 28px;
            transform:rotate(-45deg);
            display:inline-block;
            &:after, &:before {
                margin-left:0 ;
                font-size:28px;

            }          

        }

    }


    h2{
        font-size: 20px;
        color: @default-font-color;
        font-family: @heading-font-alt;
        font-weight: 600;
        letter-spacing:0.03em;
        text-transform:uppercase;
        position: relative;

        &:after{

            .transition(all, 0.3s);
            position: absolute;
            content: '';
            bottom: -8px;
            left: 0;
            display: block;
            width: 32px;
            height: 2px;
            background: @primary-color;

        }

    }

    &:hover{

        h2{

            &:after{

                background: lighten(@primary-color, 30%);
                width: 60px;
                height: 2px;
            }

        }

    }

}

.service-block-2{
        background: @pure-white-bg;
        border: 1px solid @border-color;
        position: relative;
        margin: 50px 0 24px 0;
        padding: 24px 10px;
        text-align:center;
        
        span{
            left: 50%;
            margin-left:-30px;
            color:@light-text-color;
            position: absolute;
            top: 0;
            margin-top:-30px;
            width:60px;
            line-height:60px;
            height:60px;
            background:@primary-color;
            text-align:center;
            transform:rotate(45deg);
            .transition(all, 0.9s);
            .round(4px);
        
            i.icon{
                font-size: 28px;
                transform:rotate(-45deg);
                display:inline-block;
                &:after, &:before {
                    margin-left:0 ;
                    font-size:28px;
        
                }          
                
            }
        
        }

        
        h2{
            font-size: 20px;
            color: @default-font-color;
            font-family: @heading-font-alt;
            font-weight: 600;
            margin-top:30px;
            letter-spacing:0.03em;
            text-transform:uppercase;
            position: relative;
            
            &:after{
                
                .transition(all, 0.3s);
                position: absolute;
                content: '';
                bottom: -8px;
                left: 50%;
                margin-left:-16px;
                display: block;
                width: 32px;
                height: 2px;
                background: @primary-color;
                
            }
            
            
        }
        
         &:hover{
             


            h2{
            
                &:after{
                    
                    background: lighten(@primary-color, 30%);
                    width: 60px;
                    margin-left:-30px;
                    height: 2px;
                }

            }
            
        }
        
    }
    .btn-theme{
        background: @primary-color;
        color: @light-text-color;
        display: inline-block;
        font-size:16px;
        font-weight: 600;
        padding: 16px 32px;
        position: relative;
        text-transform: uppercase;
        transition: all 0.3s ease-out 0s;
        margin-top: 24px;
        .round(0px);

        &:hover {
            background-color: darken( @primary-color, 5%);
            color: @light-text-color;
        }    

    }


// SECTION CTA

.section-cta{

    background-image: url("../images/cta_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }


    .cta-content{

        color:@light-text-color;

        h2{
            font-size:65px;
            color:@light-text-color;
            text-transform:capitalize;
            letter-spacing:0.03em;
            line-height:65px;
            font-weight:600;
            font-family: @heading-font;
        }

        h4{
            font-size:25px;
            color:@light-text-color;
            letter-spacing:0.03em;
            font-weight:600;
            font-family: @heading-font;
            text-transform:capitalize;

            span{
                font-size:40px;
                color:@primary-color;

            }

        }


        a.btn-cta-3{
            background: @dark-color;
            border: 2px solid @dark-color;
            color: @light-text-color;
            display: inline-block;
            font-size:20px;
            font-weight: 400;
            padding:10px 25px;;
            position: relative;
            text-transform: uppercase;
            transition: all 0.3s ease-out 0s;
            margin-top:40px;

            &:hover {
                background-color: lighten(@dark-color, 10%);
            }    

        }



    }

}

// TEAM SECTION


.section-our-team{
    
    clear:  both;
    
    .team-centered{
        text-align: center !important;
    }
}

.team-layout-1{
    background: @light-bg;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-top: 30px;

    .team-member{    
        position:relative;
        overflow:hidden;
        img{
            vertical-align: middle;  

        }

    }


    .team-social-share{
        background: @text-color;
        bottom: -60px;
        left: 0;
        padding: 15px 0;
        position: absolute;
        text-align: center;
        transition: all 0.4s ease 0s;
        width: 100%;      

        a{
            border: 1px solid @light-text-color;
            color: @light-text-color;
            display: inline-block;
            height: 30px;
            line-height: 30px;
            margin-right: 15px;
            width: 30px;
            font-size:14px;
            transform:rotate(45deg);

            i{
                transform:rotate(-45deg);  
            }

            &:last-child{
                margin-right: 0;  
            }

            &:hover{
                color:@primary-color;
                background:@pure-white-bg;
            }
        }

    }

    .team-info{
        text-align:center;

        h3{
            font-family: @heading-font-alt;
            text-transform: uppercase;
            margin: 15px 0 0 0;
            font-size: 20px;
            letter-spacing: 0.03em;
            font-weight: 600;
            color:@dark-text-color;
        }

        h4{
            font-family: @heading-font-alt;
            color: @separator-border;
            text-transform: uppercase;
            font-size: 15px;
            letter-spacing: 0.03em;
            font-weight: 500;

        }


    }

    &:hover{
        .team-member  .team-social-share {
            bottom: 0;
        }
    }


}


// SECTION PROCESS

.section-process{
    position: relative;
    overflow: hidden;
}

.section-process-bg{
    background-image: url("../images/process_bg.jpg");
    background-repeat: repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
}


.process-step-1{  
    padding:15px 15px 0px;
    margin-bottom: 24px;
    margin-top: 48px;
    text-align:center;
    position:relative;

    p{
        width:60px;
        height:60px;
        line-height:60px;
        left:50%;
        margin-left:-30px;
        position:absolute;
        font-size:30px;             
        background:@primary-color;
        transform:rotate(45deg);
        top:0;
        margin-top:-30px;

        span{
            transform:rotate(-45deg);
            color:@light-text-color;
        } 

    }


    h3{
        font-size: 20px;
        margin-top: 42px;
        font-family:@heading-font;
        font-weight:600;
        letter-spacing:0.03em;
        text-transform:uppercase;
        color:@text-color;
        line-height: 24px;
    }

    >span{
        height: 50px;
        margin-top: -25px;
        position: absolute;
        right: -50px;
        top: 50%;
        color:@primary-color;

        &:after{
            background: @primary-color;
            content: "";
            height: 2px;
            margin-top: 0;
            position: absolute;
            right: 2px;
            top: 8px;
            width: 70px;   

        }


    }
}

.process-step-2{  
    
    border:1px solid @border-color;
    padding:15px;
    margin-top: 48px;
    margin-bottom: 24px;
    background:@pure-white-bg;
    text-align:center;
    position:relative;

    .step-box{
        width:60px;
        height:60px;
        line-height:60px;
        left:50%;
        margin-left:-30px;
        position:absolute;
        font-size:30px;             
        background:@primary-color;
        transform:rotate(45deg);
        top:0;
        margin-top:-30px;

        span{
            transform:rotate(-45deg);
            color:@light-text-color;
            display:block;
        }             

    }

    h3{
        font-size:20px;
        margin-top:30px;
        font-family:@heading-font;
        font-weight:600;
        margin-bottom:0;
        letter-spacing:0.03em;
        text-transform:uppercase;
        color:@text-color;
        position:relative;

        &:after{

            .transition(all, 0.3s);
            position: absolute;
            content: '';
            bottom: -8px;
            left: 50%;
            margin-left:-16px;
            display: block;
            width: 32px;
            height: 2px;
            background: @primary-color;

        }


    }


    &:hover{


        h3{

            &:after{

                background: lighten(@primary-color, 30%);
                width: 60px;
                margin-left:-30px;
                height: 2px;
            }

        }

    }     

}   


// HOME PRICE PLAN

.section-price-plan{
    clear:both;
    position:relative;
    overflow:hidden;
}
    
.pricing-container-1 {
    margin-top:30px;
    background-color: @section-bg-light; 
    padding:28px 20px;     

    .Priceing-heading{
        border-bottom:3px solid @primary-color;
    }
    .pricing-container-title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing:0.03em;
        text-transform:uppercase;
        color:@text-color;
    }

    .price-content{

        .transition(all, 0.5s);
        box-sizing: border-box;
        position: relative; 
        .round(0px);

        .price-table-item{
            padding:15px 0px;
            border-bottom:1px dashed @light-border-color;
            &:last-child{
                border:0 none;
                padding-bottom:0;
            }

        }      


    }

    .pricing-container-pricing {
        display: inline-block;
        margin-bottom:10px;

        .plan-currency {
            color: @primary-color;
            font-size: 22px;
            line-height: 28px;
            font-weight: 500;
            vertical-align: top;
        }

        .plan-price {
            color: @primary-color;
            font-size: 32px;
            font-weight: 500;
            vertical-align: middle;
        }

        .plan-interval{
            color: @primary-color;
            font-size: 18px;
            font-weight: 500;
            vertical-align: middle;
            display: inline-block;
            margin-top: 12px;
        }


    }


    .pricing-container-details {
        margin-bottom: 28px;
    }

    a.price-plan-btn{
        background: @primary-color;
        color: @pure-white-bg;
        border: 2px solid @primary-color;
        font-size:15px;
        text-transform:uppercase;
        height:42px;
        line-height:27px;
        padding-left:12px;
        padding-right:12px;
        font-weight: 600;
        margin-top:25px;
        .round(0px);
        .transition(all, 0.2s);

    }


}

.pricing-container-2 {
    margin-top:30px;
    background-color: @section-bg-light; 
    padding:28px 20px;     

    .Priceing-heading-2{
        border-bottom:3px solid @primary-color;
    }
    .pricing-container-title {
        font-size: 20px;
        font-weight: 600;
        letter-spacing:0.03em;
        text-transform:uppercase;
        color:@text-color;
    }

   .price-content-2{

        p{
          margin-top:20px;  
            
        }            
        
    }
    
    .pricing-container-pricing-2 {
        display: inline-block;
        margin-bottom:10px;

        .plan-currency {
            color: @primary-color;
            font-size: 22px;
            line-height: 28px;
            font-weight: 500;
            vertical-align: top;
        }

        .plan-price {
            color: @primary-color;
            font-size: 32px;
            font-weight: 500;
            vertical-align: middle;
        }
        
        .plan-interval{
            color: @primary-color;
            font-size: 18px;
            font-weight: 500;
            vertical-align: middle;
            display: inline-block;
            margin-top: 12px;
        }
        

    }
       

    a.price-plan-btn-2{
        background: @primary-color;
        color: @pure-white-bg;
        border: 2px solid @primary-color;
        font-size:15px;
        text-transform:uppercase;
        height:42px;
        line-height:27px;
        padding-left:12px;
        padding-right:12px;
        font-weight: 600;
        margin-top:15px;
        .round(0px);
        .transition(all, 0.2s);

    }


 }


// TESTIMONIAL SECTION

.section-client-testimonial{
    position: relative;
    overflow: hidden;
}

.testimony-layout-1{
    background:@pure-white-bg; 
    position: relative;
    padding: 30px;
    margin:35px 15px 0;

    img{
        height:70px;
        max-width:70px;
        position:absolute;
        top:-35px;

    }

    .people-quote{
        font-size:32px;
        font-weight:700;
        text-transform:uppercase;
        color:@primary-color;
        margin-bottom:24px;
    }

    h6{
        color:lighten(@separator-border,5% );
        font-size: 14px;
        font-weight:500;
        margin-bottom: 0;
        text-transform: uppercase;
        display:inline-block;
    }

    h4{
        font-size: 16px;
        text-transform: uppercase;
        font-weight:500;
        letter-spacing:0.03em;
        margin: 0;
        display:inline-block;
    }


    span{
        font-size: 14px;
        text-transform: uppercase;
        color: lighten(@default-font-color, 10%);
    }

    p.testimony-text{
        position: relative;
        font-family: @body-font;
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 32px;
        margin: 20px 0 24px 0;
        color: @default-font-color;

    }


}

.testimony-layout-2{
    background:@section-bg-light;
    position: relative;
    padding: 24px 24px 30px;
    margin:35px 15px 0;

    img{
        height:70px;
        max-width:70px;
        position:absolute;
        top:-35px;

    }

    .people-quote{
        font-size:32px;
        font-weight:700;
        text-transform:uppercase;
        color:@primary-color;
        margin-bottom:24px;
    }

    h6{
        color:lighten(@separator-border,5% );
        font-size: 14px;
        font-weight:500;
        margin-bottom: 0;
        text-transform: uppercase;
        display:inline-block;
    }

    h4{
        font-size: 16px;
        text-transform: uppercase;
        font-weight:500;
        letter-spacing:0.03em;
        margin: 0;
        display:inline-block;
    }


    span{
        font-size: 14px;
        text-transform: uppercase;
        color: lighten(@default-font-color, 10%);
    }

    p.testimony-text{
        position: relative;
        font-family: @body-font;
        font-size: 20px;
        font-style: italic;
        font-weight: 500;
        letter-spacing: 0.02em;
        line-height: 32px;
        padding: 40px 0 5px 0;
        color: @default-font-color;

        &:before{
            background: @primary-color;
            color: @light-text-color;
            content: "";
            display: block;
            font-family: FontAwesome;
            font-size: 24px;
            font-style: normal;
            height: 48px;
            left: 0x;
            line-height: 48px;
            margin-left: 0;
            position: absolute;
            text-align: center;
            top: -24px;
            width: 48px;  
        }

    }


}

.testimonial-container{

    .owl-controls{
        display:block;
        position: relative;
        margin-top:0px;
        text-align:center;

        .owl-dots{

            margin: 24px 0 0 0;

            .owl-dot{

                display: inline-block;
                padding: 2px;
                width: 14px;
                height: 14px;
                background: transparent;
                border:1px solid @light-border-color;
                margin: 0 4px;

                span{
                    display: block;
                }
            }

            .active{

                span{
                    padding: 2px;
                    width: 8px;
                    height: 8px;
                    background: lighten(@primary-color, 10%);
                }

            }

        }

    }

}


// SECTION APPOINTMENT

.section-appointment{
    position:relative;
    overflow:hidden;
}

.appointment-form-wrapper{
    background: darken(@second-bg-color,5%);
    padding: 0px 5px 28px;
    // margin-top:60px;

    h3{
        font-size:28px;
        font-weight:700;
        color:@light-text-color;
        background:@primary-color;
        padding:30px;
        letter-spacing:0.03em;
        margin:0 -5px 50px;
        text-transform:uppercase;
        position:relative;                   

    }

}
.appoinment-form {

    padding: 0 12px;

    .form-control{
        font-size:16px;
    } 
    .form-group{
        margin-bottom:0;
    } 

    input[type=text],
    input[type=email],
    input[type=url],
    input[type=password]{
        border: 0px;
        border: 1px solid @border-color;
        line-height: 42px;
        height: 42px;
        margin-bottom: 24px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{

            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            border: 1px solid @border-color;

        }

    }


    textarea{
        border: 0px;
        border: 1px solid @border-color;
        padding-left: 2px;
        margin-bottom: 24px;
        padding:6px 12px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{

            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            border: 1px solid @border-color;

        }

    }

    .select-style{
        position:relative;       

        select.form-control{
            border: 1px solid @border-color;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            position:relative;
            background-image:url("../images/down_arrow.png");
            background-position:100% 4px;
            background-repeat:no-repeat;
            cursor:pointer;
            box-shadow:none;
            color:#999;
            padding:6px 12px;
            height:42px;
            margin-bottom:24px;


            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 1px solid @border-color;
                box-shadow:none;


            }             


        }
    }



    .btn-submit {
        background: @primary-color;
        border: 2px solid @primary-color;
        color: @light-text-color;
        display: inline-block;
        font-weight: 600;
        padding: 10px 24px;
        position: relative;
        text-transform: uppercase;
        transition: all 0.3s ease-out 0s;

        &:hover {
            background-color: darken(@primary-color, 10%);
        }

    }  

}


// SECTION COUNTER.

.counter-box{
    margin-top:40px;
    clear:both;
}

.counter-block-1{
    background: @pure-white-bg;
    margin: 30px 0 0;
    padding:25px 10px;
    border:1px solid @border-color;
    text-align:center;

    h4{
        margin-top: 10px;
        font-family: @body-font;
        font-weight: 600;
        font-family: @heading-font-alt;
        font-size: 20px;
        margin-bottom:0;
        text-transform: uppercase;
        color:@text-color;
        letter-spacing: 0.03em;
        position: relative;

    }

    .counter{
        display: inline-block;
        margin-top: 0px;
        font-size: 32px;
        color: @primary-color;
        font-weight: 600;
        font-family: @heading-font-alt;
    }

    .counter-postfix{
        display: inline-block;
        margin-top: 0px;
        font-size: 32px;
        color: @primary-color;
        font-weight: 600;
        font-family: @heading-font-alt;
    }

    .icon{
        display:block;
        color:@text-color;
        opacity:0.8;  
        margin-bottom:30px;


        &:after, &:before {
            font-size: 40px;
            margin-left:0 ;

        }

    }

    &:hover{
        background: @light-bg;
        .icon{
            opacity:1;  
            transform:rotateY(360deg);
            .transition(all, 0.5s);
        }

    }

}

.counter-block-2{

    margin: 50px 0 0;
    //padding:25px 0;
    text-align:center;

    h4{
        margin-top: 30px;
        font-family: @body-font;
        font-weight: 500;
        font-family: @heading-font-alt;
        font-size: 24px;
        text-transform: uppercase;
        color:@light-text-color;
        letter-spacing: 0.03em;
        position: relative;

        &:after {
            background: @primary-color;
            content: "";
            height: 5px;
            margin-right: -25px;
            position: absolute;
            right: 50%;
            top: -15px;
            width: 50px;
        }

    }

    .counter{
        display: inline-block;
        margin-top: 0px;
        font-size: 42px;
        color: @light-text-color;
        font-weight: 600;
        font-family: @heading-font-alt;
    }

    .counter-postfix{
        display: inline-block;
        margin-top: 0px;
        font-size: 42px;
        color: @light-text-color;
        font-weight: 600;
        font-family: @heading-font-alt;
    }

    .icon{

        &:after, &:before {
            font-size: 40px;
            margin-left:0 ;
            margin-right:5px;
            color:@light-text-color;
            display:none;
            //opacity:0.4;

        }

    }



}


// CLIENT SPONSORS

.section-client-logo{
    
    clear:both;


}


.logo-layout-1{

    .client-logo{

        padding: 0 6px;

        img{
            .round(0);
            width: 100%;
            margin:0;
            border: 0px solid @light-border-color;
            display: block;
            opacity: 0.9;
            .transition(all, 0.5s);
        }

        &:hover{
            img{
                border: 0px dotted lighten( @primary-color, 10%);
                opacity: 1;
            }

        }

    }

}

.logo-layout-2{

    .client-logo{
        
        padding: 0 4px;
        background: @pure-white-bg;
        img{
            .round(0);
            width: 100%;
            margin: 0 auto 24px auto;
            border: 1px solid @light-border-color;
            display: block;
            .transition(all, 0.5s);
        }

        &:hover{
            img{
                border: 1px solid lighten( @primary-color, 10%);
                opacity: 1;
            }

        }

    }

}

.logo-items{

    .owl-controls{
        margin-top: 0px;

        .owl-nav{
            text-align: center;
            display: none;
        }

        .owl-prev,
        .owl-next{
            display: block;
            margin: 0 4px;
        }
        i.logo-nav-icon{
            display: inline-block;
            background: transparent;
            border: 1px solid darken( @light-border-color, 10%);
            width: 20px;
            height: 9px;
            position: relative;

            &:after{
                position: absolute;
                top: 2px;
                left: 2px;
                width: 14px;
                height: 3px;
                background: lighten(@primary-color, 5%);
                content: '';
            }
        }
    }

}


/***********************************************************************************************/
/*  HOME PAGE 02 */
/***********************************************************************************************/

// SECTION BANNER

.section-banner{
    
    background-image: url("../images/home_1_slider_1.jpg");
    padding: 250px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    position: relative;
    overflow: hidden;
    
    &:before{
        
        background: rgba(0, 0, 0, 0.4);
        content: "";
        height: 2000px;
        position: absolute;
        top: 0;
        width: 100%;        
        
    }
    
    .banner-content{
        width: 100%;                     
        
        h3 {
            font-family: @slider-font;
            font-weight: 600;
            font-size: 35px;
            line-height: 32px;
            font-style:italic;
            text-transform:capitalize;
            letter-spacing: 0.02em;
            margin: 16px 0 0;
            color: @light-text-color;
        }

        h2 {
            font-family: @slider-font;
            font-size: 80px;
            line-height: 80px;
            font-weight: 700;
            margin: 24px 0 0;
            text-transform:uppercase;
            color: @light-text-color;

            span{
                padding: 0 3px;

            }
            
        }
        
        h2.offer-message {
            font-family: @slider-font;
            font-size: 80px;
            line-height: 80px;
            font-weight: 700;
            margin: 0px 0 0;
            text-transform:uppercase;
            color: @light-text-color;

            span{
                padding: 0 3px;
                font-weight:700;
                color: @primary-color;
            }
            
        }
        

        a.btn-slider {
            font-size: 20px;
            margin-top: 24px;
            background: @primary-color;
            border:2px solid @primary-color;
            color: @light-text-color;
            padding: 12px 24px;
            text-transform:uppercase;
            font-weight: 600;
            text-align: left;
            .round(0px);

            &:hover{
                background: darken(@primary-color, 10%);
                border:2px solid @primary-color;
                color: @light-text-color;
            }

        }
        
    }
    
}

// HOME-2 OUR SERVICE

.section-how-we-work{
    position: relative;
    overflow: hidden;
    
}

// HOME-2 TESTIMONIAL

.section-client-testimonial{
    position: relative;
    overflow: hidden;


}

// HOME-2 COUNTER

.section-counter{

    background-image: url("../images/counter_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }

}






// HOME-2 PRICE PLAN

.section-price-plan{
    clear:both;
    position:relative;
    overflow:hidden;
      



}

// HOME-2 LATEST NEWS

.section-recent-news{
    position:relative;
    overflow:hidden;
    
    .latest{
        margin:0 12px;
            
            .latest-title {
              margin-top: 20px;
              margin-bottom: 15px;
              font-size: 18px;
			  font-weight: 700;
              line-height: 36px;
              text-transform:uppercase;
              
          
                a {
                  color:@heading-font-color;
                  
                    &:hover {
                      color: @primary-color;
                    }                      
                }                 
            }
            
            .latest-details {
                  font-size: 14px;
    			  font-weight: 600;
                  margin-bottom: 20px;
                  
                span,    
    			a {
                  border-right: 1px solid @dark-text-color;
                  color: #1a1a1a;
                  display: inline-block;
                  line-height: 14px;
                  margin-right: 3px;
                  padding-right: 8px;
                  transition: all 0.3s ease 0s;
                  
                    &:last-child {
                      border-right: medium none;
                      margin-right: 0;
                      padding-right: 0;
                    }
                    
                   &:hover {
                      color: @dark-border-color;
                    }                    
                }              
            } 
            
       .blog-details{
           
               a{
                  text-transform: uppercase;
                  margin-left: 60px;
                  font-size:15px;
                  color:@text-color;
                  
                 &::after{
                    background-color:@primary-color;
                    bottom: 10px;
                    content: "";
                    height: 2px;
                    left: 20px;
                    position: absolute;
                    width: 40px;
                     
                   }
                   
                   &:hover{
                    color:@primary-color;   
                    }
                  
                }
        
        }
        
    }
        

    .owl-controls{
        display:block;
        margin-top:0px;
        

        .owl-nav div {

            color: #000000;
            font-size: 38px;
            line-height: 42px;
            margin-top: 0px;
            opacity: 1;
            position: absolute;
            top: 50%;
            margin-top: -21px;
            z-index: 1;
            -webkit-transition: all 0.3s ease-in-out ;
            -moz-transition: all 0.3s ease-in-out ;
            transition: all 0.3s ease-in-out ;
        }

        .owl-nav div.owl-prev {

            .transition(all, 0.5s);
            left: -20px;
            color: @primary-color;
        }

        .owl-nav div.owl-next {

            .transition(all, 0.5s);
            right:-20px;
            color:@primary-color;
        }

    }
    

        
}

// NEWSLETTER SECTION

.section-newsletter{
    position: relative;
    overflow: hidden;

}



.horizontal-newsletter{
    position:relative;
    overflow:hidden;
    margin-top: -12px;
    margin-bottom: 12px;

    .news-subscription{

        >input {
            background: @pure-white-bg;
            border-color: @light-border-color;
            border-style:solid;
            border-width:1px 1px 1px 1px;
            border-radius: 0px;
            color: @text-color;
            height: 55px;
            padding-left: 13px;
            width: 100%;
        }
        > button {
            background: @primary-color;
            border: 0 none;
            border-radius: 0px;
            color: @light-text-color;
            font-size: 20px;
            font-weight: 600;
            text-transform:uppercase;
            height: 55px;
            width:120px;
            position:absolute;
            right:0;
            transition: all 0.3s ease 0s;
            &:hover{
                background: darken(@primary-color, 10%);
            }  
        }


    }

}

/***********************************************************************************************/
/* MAIN CONTENT */
/***********************************************************************************************/

.main-content{
    margin: 48px 0;
}



//SECTION SHARE

.btn-social-icon {
    height: 42px;
    width: 42px;
    border: 0;
    border-radius: 0px !important;
    margin: 3px 3px;
}

.btn-social-icon .fa{
    line-height: 42px;
    color: @light-text-color;
}

.btn-goggle-plus {
    background-color: #26b84b;
}

.btn-goggle-plus:hover {
    background-color: #20993e;
}

.btn-envelope-o {
    background-color: #d74b3e;
}

.btn-envelope-o:hover {
    background-color: #ad3d32;
}


.section-transparent-bg{
    background: transparent !important;
}

/***********************************************************************************************/
/* 05. BLOG LAYOUT */
/***********************************************************************************************/



.single-post{

    margin-bottom: 50px;

    p{
        line-height: 30px;
    }

    .single-post-content{
        margin-bottom: 30px;
        padding: 6px;
        background: @blog-bg-color;
    }

    .single-post-title{
        line-height: 24px;

        h2{
            font-size: 28px;
            line-height: 32px;
            line-height: 30px;
            font-weight: 500;
        }

        .single-post-meta{
            font-size: 15px;
        }
    }

}

.readmore{
    border:0px;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 12px 24px;
}

.readmore-sm{
    border:0px;
    border: 1px solid @primary-color;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 6px 12px;
    
    .transition(all, 0.5s);
    &:hover,
    &:focus{
        border: 1px solid @primary-color;
    background: transparent;
    color: @primary-color;
    }
}

/***********************************************************************************************/
/* 06. BLOG SINGLE PAGE */
/***********************************************************************************************/

.single-post-inner{
    background: @blog-bg-color;
    padding: 30px;
}

.post-inner-featured-content{
    display: block;

    img{
        display: block;
        width: 100%;
        text-align: center;
    }
}

.single-post-inner-title{

    h2{
        font-size: 32px;
        margin: 32px 0 24px 0;
    }
    margin-bottom: 24px;
}

.single-post-inner-meta{

    h2{
        font-size: 24px;
    }

    .tag-list{
        a{
            display: inline-block;
            padding: 4px 12px;
            margin-right: 3px;
            background: @primary-color;
            color: @light-text-color;
        }
    }
}

.custom-post-info{margin:0; padding:0;}

.custom-post-thumb-small{width: 70px; height: 70px; }
.custom-post-thumb-medium{width: 350px; height: 120px;}

.single-post-container{

    margin: 0 0 24px 0;

    h3{
        margin: 24px 0 12px 0;
        font-size: 18px;
        text-transform: capitalize;

        a{
            color: @heading-font-color;

            &:hover{
                color: @text-color;
            }
        }
    }

    .custom-breadcrumb{

        font-size: 18px;        
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid @light-border-color;

    }


    .article-meta-extra{

        border-top: 1px solid @light-border-color;
        border-bottom: 1px solid @light-border-color;
        padding: 6px 0;
        margin-bottom: 12px;

        span{

            margin-right: 5px;

            &:last-child{
                margin-right: 0px;
            }

        }


        i{
            color: @sub-text-color;
            margin-right: 3px;
        }

    }


    h2+p{

        margin-top: 12px;
    }


}

.single-post-content{

    h1{
        margin: 12px 0;
    }

    h2{

        margin: 12px 0;

    }

    h3{
        margin: 12px 0;
    }

    h4{

        margin: 12px 0;

    }

    h5{
        margin: 12px 0;
    }

    h6{
        margin: 12px 0;

    }

}

.articles-nav{
    margin-top: -1.5em;
    margin-bottom: 1.5em;
    padding: 0 2.4em;

    .articles-nav-prev{
        float: left;
    }

    .articles-nav-next{
        float: right;
    }

}



.aligncenter, .alignleft, .alignright, .alignnone {

    margin: 12px 0 24px 0;
    max-width: 100%;
    height: auto; 

}

.aligncenter, img.centered {

    display: block;
    margin: 12px auto;
}


.alignleft {
    float: left;
}

.alignright {
    float: right;

}

.alignnone {
    clear:both;
}

.wp-caption{

    text-align:center;

    img{

        max-width: 100%;
        max-height: auto;

    } 

    .wp-cap{
        margin-bottom: 0px;
        font-size: 12px;

    }
}



.post-navigation {

    text-align:center;
    clear: both;

    p {

        text-transform: uppercase;

        a{

            position: relative;
            padding-left: 24px;
            margin-right: 5px;

            &:before{

                content:"";
                position:absolute;
                width:12px;
                height:12px;
                top:4px;
                left:3px;
                background: transparent;
                border: 1px solid @light-border-color;   
                .transition( 0.3s);

            }

            &:hover{

                &:before{

                    background: @primary-color;
                    border: 1px solid @text-color;   

                }


            }

            &:first-child{
                margin-right: 0px;
            }

        }

    }

}



.comments-nav-section,
.post-nav-section{
    margin: 24px 0 0 0;

    p {
        margin-bottom: 0px;
    }

    a{

        .round(0px);
        text-align: center;
        .transition(0.3s);
        border: 1px solid @light-border-color;
        .drop-shadow(0, 1px, 1px, 0, 0);
        background: transparent;
        color: @text-color;
        padding: 6px 12px;

        &:hover{
            border: 1px solid @primary-color;
            color: @primary-color;
            background: transparent;
        }

    }

}

.article-share-section{
    margin: 24px 0 0 0;
    border: 1px solid @light-border-color;
    border-left: 0px;
    border-right: 0px;
    padding: 14px 0 10px 0;
}



.article-author {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;
    min-height: 100px;
    padding-bottom: 24px;


    .author-avatar img {
        width: 100px;
        max-width: 100%;
        max-height: auto;
        float: right; 
        border: 1px solid @light-border-color;
        padding: 3px;
        margin-top: 0px;
        margin-left: 12px;
    }

    .about_author{
        margin-bottom: 24px;
    }

    .social-icons{
        a{
            display: inline-block;
            color: @primary-color;
            margin-right: 15px;

            &:hover{
                color: @primary-color;
            }
        }
    }


}

.related-post {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;    

    ul {

        li {
            margin: 5px 0;
            a {

                position: relative;                      
                .transition( 0.3s);
                color: @default-font-color;

                &:hover {

                    color: @primary-color;

                }


            }


        }
    }
}

// PAGINATION.



ul.pagination {

    li {


        a {
            color: @primary-color;
            .round(0px);
            padding: 6px 12px;
            border: 0px;
            margin: 0 2px;

            &:hover{
                backgroud: #000 !important;
            }
        }


        a.current{
            background: @primary-color;
            color: @light-text-color;
            border: 0px;
        } 


        &:first-child {
            .round(0px);
            a {

            }
        }

        &:last-child {

            .round(0px);
            a {
            }
        }
    }

}


// COMMENT LISTS

.comments-area {

    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;

}
.article-add-comments {
    float: right;
    display: inline-block;	
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align:center;
    color:@sub-text-color;		
    border: 1px solid @light-border-color;
    background: @background-color;
    .transition(0.3s);

    &:hover {
        border: 1px solid @primary-color;
        color: @primary-color;

    }


}

.commentslist {

    margin-top: 24px;
    counter-reset: comment-id;

    li{
        list-style-type : none;

        margin-left: 0px;

        &:first-child{

            margin-top: 24px;

        }

    }



    li+li{
        margin-top: 24px;
    }

    li ol, li ul {
        padding-left: 3em;
        margin-left: 3em;
        border-left: 1px dotted @light-border-color;
        list-style-type : none;
    }

    li ol li, li ul li{
        position: relative;
        &::before {
            content: '';
            width: 2em;
            height: 1px;
            border-bottom: 1px dotted @light-border-color;
            position: absolute;
            left: -3em;
            top: 2em;
        }
    }

    li article {
        background: @background-color;
        padding: 12px;


        header { 
            border-bottom: 1px solid @light-border-color;
            font-size:12px;
            margin-bottom: 1.5em;
            position: relative;

            span {				
                color: lighten(@text-color,30%);
            }

            &::before {
                counter-increment: comment-id;
                content: counter(comment-id);
                position: absolute;
                right:5px;
                top: 15px;
                color: @light-color;
                font-size: 32px;
                font-family: @heading-font;
            }

            h5 {
                margin-bottom: 12px;
                text-transform: capitalize;
                color: @heading-font-color;

                a{
                    color: @heading-font-color;
                    &:hover{
                        color: @text-color;
                    }
                }     

                span {
                    font-size: 12px;
                    background: @dark-color;					
                    color: @light-text-color;
                    padding: 3px 5px;
                    font-style: normal;
                    margin-right:.5em;

                }
            }

        }

        .comment_text{
            padding-right: 24px;
        }
    }

}


.comment-avatar img {
    width: 64px;
    height: 64px;
    float: right;
    background: #FFFFFF;                
    margin: 2px;
    border: 1px solid @light-border-color;
    padding: 2px;
    .transition(0.3s);


    &:hover{
        border-color: darken(@light-border-color, 15%);
    }
}  

.waiting-moderation {
    color: lighten(@text-color,30%);

}

.comments-nav-section{
    margin: 48px 0;

    .btn-primary{
        background: @pure-white-bg;

        &:hover{
            background: @pure-white-bg;
        }
    }
}


// COMMENT FROM

#comment-form{

    label{
        margin-bottom: 12px;
    }

    input[type=text],
    input[type=email],
    input[type=url],
    input[type=password]{
        border: 0px;
        border-bottom: 1px solid @border-color;
        line-height: 42px;
        height: 42px;
        margin-bottom: 24px;
        padding: 0 12px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);

            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }


    textarea{
        border: 0px;
        border-bottom: 1px solid @border-color;
        padding: 12px;
        margin-bottom: 24px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{

            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            border: 0px;
            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }

}


/***********************************************************************************************/
/* PAGE HEADR AND BREADCRUMB */
/***********************************************************************************************/

.page-header{
    margin: 0;
    padding: 80px 0 80px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-attachment: inherit;
    background-position:center center;
    background-color: transparent;
    background-image: url('../images/header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px solid #FFF;

    &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        top: 0;
        left: 0;
        content: '';
        z-index: -1;
    }

    h3{
        color: @light-text-color;
        text-align: center;
        font-size: 36px;
        line-height: 24px;
        font-weight: 500;
        font-family: @heading-font-alt;
        margin: 24px 0;
        text-transform: uppercase;
        letter-spacing: 0.02em;
    }

    .page-breadcrumb{
        font-size: 16px;
        color: @light-text-color;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.01em;

        a{

            color: @light-text-color;

            &:hover{
                color: @primary-color;
            }
        }
    }

}



/***********************************************************************************************/
/* 07. EVENTS/CAMPAIGN PAGE */
/***********************************************************************************************/

.event-search-box{
    margin-bottom: 48px; 
    padding: 24px; 
    background: @light-bg;
    border: 1px solid @light-border-color;


    input{
        border: 0px;
        border-bottom: 1px dotted darken(@light-border-color,20%);

        &:focus{
            border-bottom: 1px dotted darken(@light-border-color,50%);
        }
    }

    button{
        border-radius: 0px;
    }

    .btn-event-search {
        background-color: @primary-color;
        border-color: @primary-color;
        color: #fff;
        &:hover{
            background-color: darken(@primary-color,10%);   
        }
    }

}


// EVENT SINGLE.

.article-event {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;
    min-height: 100px;

    border-bottom:  0px solid @light-border-color;
    padding-bottom: 24px;

    .single-post-inner{
        padding: 12px 0px;
    }
}


/***********************************************************************************************/
/* ABOUT US PAGE */
/***********************************************************************************************/

.about-us-header{
    background-image: url("../images/our_mission_bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}


.section-heading-bold{
    position: relative;
    font-size: 32px;
    margin-bottom: 32px;
    line-height: 48px;

}

/***********************************************************************************************/
/* SINGLE TEAM PAGE */
/***********************************************************************************************/


.single-team{
background: transparent;
margin: 20px 0 30px;
overflow: hidden;
position: relative;

img{
    width:100%;
    
 }

}

.single-team-details{
    
     h3 {
        font-family:@heading-font;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.03em;
        margin: 12px 0 0;
        text-transform: uppercase;
    }

    h4 {
    color: lighten(@separator-border,10%);
    font-family: @heading-font;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    }
    
ul.team-social-share-2 {
       li {
        background: @primary-color;
        border-radius: 0;
        color: @light-text-color;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        margin-bottom: 20px;
        margin-right: 15px;
        text-align: center;
        width: 40px;
        
        a{
         color: @light-text-color;   
       }
    }
}

a.btn-team-details {
    border: 2px solid @primary-color;
    border-radius: 0;
    margin: 10px 0;
    padding: 10px 15px;
    text-transform: uppercase;
}
}



/***********************************************************************************************/
/* SERVICE PAGE */
/***********************************************************************************************/

.service-header{
    background-image: url("../images/home_1_banner.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.single-service{
    
}    

.service-bottom-layout{
   margin-top:30px; 
    
}    
    
.single-service-content{
   h3{
       font-size:25px;
       text-transform:uppercase;
       font-weight:600;
       letter-spacing:0.03em;
       margin-top:15px;
     } 
     
     ul{

         list-style-type: none;
         margin-bottom: 16px;
         overflow:hidden;

         li{
             padding-left: 32px;
             position: relative;

             &:before{
                 position: absolute;
                 content: '';
                 font-family: FontAwesome;
                 display: inline-block;
                 color: @primary-color;
                 margin: 0 11px 0 0;
                 position: absolute;
                 left: 0px;
             }
         }


     }
 
 }


/***********************************************************************************************/
/*  FAQ PAGE */
/***********************************************************************************************/

.section-faq{
    position:relative;
    overflow:hidden;



    .panel{
        box-shadow:none;   
    }

    .panel-default{
        border: 1px solid @border-color;
        padding: 17px 15px;
        background:@pure-white-bg;
        border-radius: 0;
    }

    .faq-box{

        .panel-heading{
            background:none;
            border:0 none;
            display:block;
            padding: 0;
        }

        .panel-title {
            letter-spacing: 0.02em;
            margin-bottom: 0;
            margin-top: 0;
            font-size:inherit;
            text-transform:capitalize;
        }    
        .panel-title >a{
            display:block;

            &:hover,
                &:focus{
                outline:none;
                color:@text-color;
            }
        }

        .panel-body{
            padding-left:0px;   
        }

        .panel-heading [data-toggle="collapse"]:after {
            font-family: 'FontAwesome';        
            content:"";
            float: left;
            color: @sub-text-color;
            font-size: 16px;
            margin-right:10px;       
        }

        .panel-heading [data-toggle="collapse"].collapsed:after {
            color: @sub-text-color;
            content: "";
        }

    }

}



/***********************************************************************************************/
/*  COMING SOON PAGE */
/***********************************************************************************************/


.bcfix-countdown-section{
    
    padding: 170px 0;
    background: transparent;
    background-image: url('../images/coming-soon.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    display: block;
    clear:both;
    position: relative;
    overflow: hidden;

    &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.4);
        top: 0;
        left: 0;
        content: '';
    }

    #bcfix_election_countdown{
        font-size: 32px;
        clear: both;
        width: 900px;
        max-width: 100%;
        margin: 30px auto;
        
        .countdown_container{
            
            text-align: center;
            display: block;
            float: left;
            width: 25%;
            
            span.count_number{
                display: block;
                font-size: 90px;
                line-height: 100px;
                font-weight: 600;
                font-family: @heading-font;
                color: @light-text-color;
            }
            span.count_text{
                display: block;                
                font-size: 32px;
                line-height: 42px;
                font-weight: 400;
                font-family: @body-font;
                color: @primary-color;
                text-transform: uppercase;
            }

        }

        
    }

    h2{
        color: @light-text-color;
        margin-top: 48px;
        font-family: @heading-font;
        font-weight: 400;
        letter-spacing: 0.03em;
    }
}


/***********************************************************************************************/
/* 09. GALLERY PAGE */
/***********************************************************************************************/


.section-gallery-block-parallax{
    
    background-attachment: scroll;
    background-color: transparent;
    background-image: url('../images/bg-geometry.png');
    background-repeat: repeat;
    background-size: initial;
}

.section-gallery-block{
    clear: both;
}



.gallery-container{
    padding: 12px;
    margin: 0;
    
}



.no-bottom-padding{
    padding-bottom: 0px;
}

.gallery-light-box{
    
    display: block;
    overflow: hidden;
    position: relative;
    
    .gallery-img{
       
        display: block;
        position: relative;
        max-width: 100%;
        padding: 0px;
        
        &:before{
            background: rgba(214, 153, 107, 0.1);
            width: 100%;
            height: 0px;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            .transition(all, 0.5s);
        }
        
        &:after{
            font-family: FontAwesome;
            content: '\f1c5';
            position: absolute;
            right: 150%;
            margin-left: -21px;
            bottom: -64px;
            background: @light-text-color;
            color: @primary-color;
            text-align: center;
            width: 42px;
            height: 42px;
            line-height: 42px;
            .round(4px);
            .transition(all, 0.5s);
            
        }
    
        img{
            width: 100%;
            max-width: 100%;
            display: block;
            opacity: 1;
            .transition(all, 0.7s);
        }
        
    }
    
    &:hover{
        
        
        .gallery-img{
            
            &:before{
            background: rgba(214, 153, 107, 0.5);
            width: 100%;
            height: 1500px;
        }
            
            &:after{
                
                opacity: 1;
                top: 50%;
                left: 50%;
                margin-top: -24px;
            }
          
            img{
               
                opacity: 0.4;
            }
            
            figcaption{
                bottom: 0px;
                left: 0;
            }
            
        }
        
        
        
    }
    
    
 
    
}


.no-padding-gallery{
    
    padding: 0 !important;
    
    .gallery-container{
        padding: 0px;
        margin: 0;

    }
    
    .gallery-carousel .owl-controls{
        margin: 0;
    }
    
}

.filterable-gallery{
    
    clear: both;
    
    .filter-items{
        text-align: center;
        margin-bottom: 32px;
        
        button{
            
            text-transform: uppercase;
            .round(0);
            margin-right:4px;
            border: 1px solid @light-border-color;
            
            &.active{
                background: @primary-color;
                color: @light-text-color;
            }
            
            &:last-child{
                margin-right: 0px;
            }
            
            &:hover{
                background: @primary-color;
                color: @light-text-color;
            }
        }
    }
    
}
   .gallery-carousel{
        
         .owl-controls{
             
            display: block;
            margin-top: 20px;
            text-align:center;
            
            .owl-nav{
                text-align: center;
            }
            
            .owl-prev,
            .owl-next{
                display: inline;
                margin: 0 4px;
            }
            
        .owl-dot{
        
            display: inline-block;
            padding: 2px;
            width: 14px;
            height: 14px;
            background: transparent;
            border:1px solid @light-border-color;
            margin: 0 4px;
            
                span{
                    display: block;
                }
            }
            
            .active{
                
                span{
                    padding: 2px;
                    width: 8px;
                    height: 8px;
                    background: lighten(@primary-color, 10%);
                }
                
            }            

        }
        
    }  





/***********************************************************************************************/
/* 11. 404 PAGE */
/***********************************************************************************************/

.section-404{
    background: @second-bg-color;
}

.message-container-404{

    margin: 48px 0;

    .text-404{
        font-size: 170px;
        font-weight: 600;
        letter-spacing: 0.1em;
        font-family: @heading-font-alt;
        text-align: center;
        margin:80px 0;
        color:@primary-color;

        span {
            color: darken(@primary-color,10%);
        }
    }

    .search-form-404 {

        margin: 0 auto;
        padding: 5px 5px;    
        width: 40%;

    }


    .message-text-404{
        padding: 24px 160px;
        display: block;
        text-align: center;

    }

}


/***********************************************************************************************/
/* CONTACT PAGE */
/***********************************************************************************************/

.section-contact-block{

    position: relative;
    overflow: hidden;

    .content-block{
        padding:  0 48px 0 0;
    }

    .contact-title{
        font-size: 24px;
        border-bottom: 3px solid #eeeeee;
        color: #5c5c5c;
        padding-bottom: 15px;
        position: relative;

        &:after {
            bottom: -3px;
            content: "";
            height: 3px;
            left: 0;
            position: absolute;
            width: 32px;
            background: @primary-color;
        }

    }


    .contact-info{

        li{
            margin-bottom: 24px;
        }

        .icon-container{
            display: inline-block;
            background: @primary-color;
            color: @light-text-color;
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            transform:rotate(45deg);
            text-align: center;
            margin-right: 10px;

            i{
                transform:rotate(-45deg);
            }
        }

        address{
            display: inline-block;
        }

        a{

            color: @default-font-color;

            &:hover{
                color: @primary-color;
            }

        }
    }

    // Contact Form

    .contact-form-block{

        padding: 0 0 0 0px;

    }

    #contact-form{

        input[type=text],
        input[type=email],
        input[type=url],
        input[type=password]{
            border: 0px;
            border-bottom: 1px solid @border-color;
            line-height: 42px;
            height: 42px;
            margin-bottom: 24px;
            padding-left: 2px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 0px;
                border-bottom: 1px solid lighten(@dark-border-color,50%);

            }

        }


        textarea{
            border: 0px;
            border-bottom: 1px solid @border-color;
            padding-left: 2px;
            margin-bottom: 24px;
            .round(0);
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            .transition( 0.3s);
            &:focus{

                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                border: 0px;
                border-bottom: 1px solid lighten(@dark-border-color,50%);

            }

        }

    }


}



/***********************************************************************************************/
/* 12. FOOTER */
/***********************************************************************************************/

footer {
    position: relative;
    overflow: hidden;
  
    

        .footer-intro{
            margin-bottom: 32px;
        }
    
        a{
            color: @light-text-color;
    
            &:hover{
                color: darken(@primary-color,10%);
            }
        }
        
        .social-icons{
            clear: both;
            margin-top:20px;
            a{
                border: 1px solid @primary-color;
                background:@primary-color;
                color: @light-text-color;
                display: inline-block;
                font-size: 14px;
                height: 30px;
                line-height: 30px;
                margin-right: 15px;
                position: relative;
                text-align: center;
                .transition(all,0.3s);
                width: 30px;
                transform:rotate(45deg);
                .round(4px);
                
                i{
                 transform:rotate(-45deg);   
                }
        
                &:hover{
                    background: darken(@primary-color, 10%);
                    color: @light-text-color;
                }
            }
        
        }        
    
    
    }

    footer p{
        color: @light-text-color;  
    }

.about-footer {
    border-bottom: 1px solid @dark-bg;
    margin-bottom: 60px;
    padding-bottom: 50px;
}


.footer-widget-area {
    padding: 80px 0 80px;


    .footer-widget {

        .footer-logo {
            margin-bottom: 15px;
        }               


    }


    .footer-subscription{

        input[type="email"] {
            background: @pure-white-bg;
            border: 0 solid @light-border-color;
            border-radius: 0;
            height: 48px;
            color:@text-color;

            &:focus {
                box-shadow: none;
                color:@text-color;
            }          

        }

        input[type="submit"] {
            background: @primary-color;
            border: 0 solid @light-border-color;
            color: @light-text-color;
            height: 42px;
            letter-spacing: 2px;
            margin-top: 6px;
            padding: 0 18px;
            text-transform: uppercase;
        } 

    } 


    .footer-widget-header h3{

        position: relative;
        font-size: 24px;
        margin-bottom: 24px;
        text-transform: uppercase;
        font-family: @heading-font;
        letter-spacing: 0.03em;
        font-weight: 500;
        display: inline-block;
        color: @light-text-color;



        span{
            font-style: 400;
            color: @heading-font-color;
        }
    }


    .footer-useful-links{
        clear: both;

        li{
            margin-bottom: 8px;
            width:50%;
            float:left;
        }
    }



    .fa-footer{
        display: inline;
        text-align: center;
        width: 12px;
        height: 12px;
        color: @primary-color;
        padding: 4px 6px 6px 0;
        margin-right: 0px;
    }

    .contact-us{

        clear:both;
        
        p{
            padding-left: 55px;
        }
    }

   span{
            display: inline-block;
            width: 30px;
            height: 30px;
            line-height:30px;
            .round(4px);
            transform:rotate(45deg);
            color:@light-text-color;
            background:@primary-color;
            font-size:14px;
            text-align: center;           
            position:absolute;       
       
        i.fa-contact{
          transform:rotate(-45deg);
          text-align: center;
        }  
    
     }
       

    .footer-widget + .footer-widget {
        margin-top: 24px;
    }

    li{
        line-height: 32px;
    }



}

.footer-widget-area-bg{
    background:@footer-bg;
}

.footer-contents{
    background:#1b1b1a;
    border-top: 1px solid @dark-bg;
    padding: 28px 0 20px 0;
    
}                
  


.footer-nav{

    li{
        display: inline;
        list-style-type: none;

        a{
            padding: 6px 12px;
            position: relative;

            &:after{
                content: '';
                position: absolute;
                display: inline-block;
                top: 14px; 
                right: 0;
                margin: 0;
                height: 8px;
                width: 1px;
                background: darken(@pure-white-bg, 50%);
            }


        }


        &:last-child{
            a{
                &:after{
                    content: '';
                    position: absolute;
                    display: inline-block;
                    top: 12px; 
                    right: 0;
                    color: @primary-color;
                    margin: 0;
                    height: 8px;
                    width: 0px;
                    background: @pure-white-bg;
                }
            }
        }

    }

}

/***********************************************************************************************/
/* 13. SIDEBAR & WIDGETS */
/***********************************************************************************************/

.widget{
    background: @blog-bg-color;
    margin-bottom: 30px;
    padding: 20px;
}

.widget-title {

    position: relative;
    font-size: 24px;
    margin-bottom: 24px;
    text-transform: capitalize;
    font-family: @heading-font;
    letter-spacing: 0.03em;
    font-weight: 500;
    display: inline-block;
    color: @dark-text-color;

    span{
        font-style: 400;
        color: @heading-font-color;
    }

}

.widget ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.widget ul li {
    padding: 5px 0;
}
.widget ul li a {

    color: @default-font-color;

    &:hover{
        color: @primary-color;
    }
}

// Search Widget.

.search-form {

    margin: 0 0 12px 0;

    span.input-group-addon{

        .round(0px);
        background: @primary-btn-bg;
        border-right: 0px;
        color: @light-text-color;
        font-weight: bold;
        width: 10%;
        padding: 0 20px;

    }

    input[type="text"] {
        box-shadow: 0;

        margin-bottom:0;        
        font-size: 14px;
        padding-left: 5px;
        height: 42px;
        border: 0px;
        box-shadow: none;
        border-bottom: 1px solid @border-color;
        width: 100%;
        .transition(0.3s);
        &:focus{
            box-shadow: none;
            border-bottom: 1px solid darken(@border-color,10%);
        }

    }

}

// Category.

.widget-post-category {

    margin: 11px auto;

    li {
        margin: 5px 0;
        padding: 0;
        text-transform: capitalize;

        a {

            font-size: 15px;
            position: relative;                   
            .transition( 0.3s);

        }

    }

}

// Recent Posts.

.single-recent-post {

    margin-bottom: 26px;
    overflow: hidden;

    a {
        color: @default-font-color;
        margin-bottom: 8px;
        display: block;
        &:hover{
            color: @primary-color;
        }

    }

    span {
        
        i{
            font-size: 16px;
        }
        
        font-size: 14px;
        display: block;
        color: @primary-color;
        padding-left: 2px;
    }

}

.single-post-thumb {
    float: left;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 5px;
    width: 100px;
}

// Tags.

.widget-recent-tags {

    margin: 0 auto;

    li {
        margin: 0px 5px 7px 0px;
        float: left;

        a{

            padding: 3px 7px;
            text-transform: capitalize;
            text-align: center;           
            border: 1px solid @primary-color;
            display: block;
            background: transparent;
            .transition(all, 0.3s);

            &:hover,
                &:active {                
                background: transparent;
                border: 1px solid @primary-color;
                background: @pure-white-bg;
            }

        }

    }

} 

/***********************************************************************************************/
/* Widget Link Color */
/***********************************************************************************************/

.sidebar-widget{

    a{

        color: @text-color;
        .transition(0.3s);
        &:hover{
            color: @primary-color;

        }

    }

}


/***********************************************************************************************/
/* CALENDAR WIDGET */
/***********************************************************************************************/

#calendar_wrap {

    margin: 0 auto 5px auto;

    #wp-calendar {

        width: 100%; 

        border: 1px solid @light-border-color;
        padding: 2px;

        caption {

            border-color: @light-border-color;
            border-image: none;
            border-style: solid solid none;
            border-width: 1px 1px medium;
            font-weight: bold;
            padding: 10px 0;
            text-align: center;
            text-transform: uppercase;
        }

        thead { 

            border-right: 1px solid @light-border-color;

        }

        thead th {
            border-right: 1px solid @light-border-color;
        }

        th {
            background: none repeat scroll 0 0 #F9F9F9;
            border-bottom: 1px solid @light-border-color;
            border-right: 1px solid @light-border-color;
        }
        tr th, thead th {
            font-weight: bold;
            padding: 9px 0;
            text-align: center;
        }

        tbody {
            color: #aaa; 

            tr td {
                border-bottom: 1px solid @light-border-color;
                border-right: 1px solid @light-border-color;
                padding: 6px;
                text-align: center;
                background: #f5f5f5; 
                .round(0px);
                text-align: center;                     
                .transition(0.3s);

                &:hover{
                    background: @light-bg;
                }
            }

            .pad {
                background: none; 
            }
        }

        tfoot{

            tr td {
                border-bottom: 1px solid @light-border-color;
                border-right: 1px solid @light-border-color;
                padding: 6px;
                text-align: center;
            }

            #next { 
                font-size: 12px; 
                text-transform: uppercase; 
                text-align: center;
            }
            #prev { 
                text-align: center;
                font-size: 12px; 
                text-transform: uppercase; 
                padding-top: 10px; 
            }
        }

        #today {
            background-color: @primary-color;
            color: @light-text-color;
            font-weight: bold;
        }

    }

}

/***********************************************************************************************/
/* 14. RESPONSIVE STYLING */
/***********************************************************************************************/
/* Tab Portrait Size to Tab Landscape Size (devices and browsers) */

@media only screen and (min-width:991px) and (max-width: 1200px)  {
    
    .bcfix-header-top {

        .repair-icon-box {
            .icon-container {
                font-size: 25px;
            }
            .text {
              font-size: 13px;
                .head-heading{
                    font-size: 13px;
                }
           }
       }
        .btn-quote {
              .btn-request_quote{
                    font-size: 12px;
                    padding: 12px 3px;
                }
            }

    }

// TOP TEXT

    .main-top-header{
        p{
          font-size:13px;
        }
    }
    
        #slider_1{

        .slider-content {

            h2 {
                font-size: 50px;
                line-height: 52px;
            }

            h3 {

                font-size: 25px;
            }
            
            p{
                font-size: 20px;
                line-height: 25px;   
            }


        }

    }
    
}

@media only screen and (min-width: 992px) and (max-width: 1370px) {

    /*----- SLIDER 1 ----*/

    #slider_1{

        text-align: center;
        
        .slider_item_container{
            min-height: 520px;
        }

        .item img {
            display: block;
            width: 100%;
            height: auto;
        }

        .slider-content {

            margin-top: 24px;

            h3 {
                font-weight: 400;
                font-size: 24px;
                line-height: 32px;
            }

            h2 {
                font-size: 42px;
                line-height: 48px;
            }


        } 

    } 
    
    /*----- Banner ----*/

    .section-banner{    
        padding:130px 0px;  

        .banner-content{
            
            clear: both;
            
            h2 {
                font-size: 60px;
                line-height: 60px;
            }

            h2.offer-message {
                font-size: 60px;
                line-height: 60px;
            }
            
        }
    } 

}


/* Tablet Portrait size to standard 960 (devices and browsers) */

@media only screen and (max-width: 991px) {
   
    .bcfix-header-top {
        .bcfix-col-logo {
            text-align: center;
            }
    }

    .top-bar{
        text-align: center;
    }
    
    .top-donation-btn-container{
        text-align: center;
        margin-top: 12px;
        margin-bottom: 24px;
    }
    
    a.logo{
        text-align: center;
    }
    
    .navbar-default {
    
        .navbar-nav li ul.drop-down{
            top: 68px;
        }

        .navbar-header{
            width: 100%;
            text-align: center;
            clear: both;
            float: none ;
            margin: 0 ;
        }

        .navbar-toggle{
            span.icon-bar{
                height: 1px;
            }
        }

        .navbar-right {
            float: none;
            padding:0;
            margin: 12px 110px 0 0;
        }

        .navbar .navbar-collapse {
            text-align: center;
        }
    
    }
    
    .top-location-info{
        display: block;
        text-align: center;
    }
    
    .main-menu-container{

        float: none;

    }
        

    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }


    .navbar-nav > li {
        margin-left: 0;
    }
    .nav > li > a {
        padding: 15px 12px;
        padding-bottom: 22px;
    }
    header.one-page .navbar-nav > li > a {
        padding: 15px 5px;
        font-size: 15px;
        padding-bottom: 22px;
    }



    // SLIDER 1

    #slider_1{

        text-align: center;
        
        .slider_item_container{
            min-height: 350px;
        }

        .item img {
            display: block;
            width: 100%;
            height: auto;
        }


        .slider-content {
            top: 50%;
            transform: translateY(-48%);

            h2 {
               
                 font-size: 24px;
                 line-height: 36px;
                 padding-bottom:0px;
                 margin-bottom:16px;

                 &:after{
                     position:absolute;
                     content:"";
                     background:@primary-color;
                     width:100px;
                     height:2px;
                     left: 2px;
                     bottom: -8px;
                 }

             }

             h3 {
                 font-size: 16px;
                 line-height: 24px;
             }

             a.banner-video-icon {
                 border: 2px solid #f2f2f2;
                 color: @primary-color;
                 width: 60px;     
                 height: 60px;
                 font-size: 24px;
                 line-height: 55px;
                 margin: 0 auto 12px;

                 >i{
                     margin-left:6px; 
                 }

                 &:hover{
                     border: 2px solid @primary-color;

                 }
             }


        } 

    }
    
    
    //  PAGE HEADER
      
       
      .page-header{

        padding: 80px 0;

       }


    .main-sidebar{
        width: 100%;
        border: 0px solid transparent;
    }

    .logo{

        text-align: center;
    }
  
    
    // HOME PROCESS BLOCK.
    
     .process-step-1{  
        position:relative;

        h3{
            font-size:16px;
            line-height: 22px;
            margin-top:42px;
            font-weight: 500;
        }

        >span{
            display: none;
        }

    }
    

    // CLIENT TESTIMONIAl.
        
    .section-client-testimonial{
        .testimonial-container{
            .owl-controls{
                display:none;
            }
        }
    }

    // HOME-2 CLIENT TESTIMONIAl.
    
    .section-client-testimonial{
        .testimonial-container-2 {
                .owl-controls{
                    display:none;
            }
            
        }
    }

    // HOME-2 Gallery.
    
    .gallery-carousel{
        .owl-controls{
            display:none;  
        }
    }

    
   // COUNTDOWN SECTION

    .bcfix-countdown-section{

        #bcfix_election_countdown{
            
            font-size: 24px;

            .countdown_container{
                span.count_number{
                    font-size: 72px;
                    line-height: 60px;
                }
                span.count_text{
                    font-size: 24px;
                    line-height: 42px;
                }

            }

        }

    }        

    .footer-widget-area {

        padding: 42px 0 0 0;

    }

    .footer-widget{
        margin-bottom: 36px;
    }

    .footer-intro{
        display: none;
    }

    .footer-nav{
        display: none;
    }

    .copyright-text{
        text-align: center;
    }

}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {

    .top-bar{
        text-align: center;
    }

    .top-location-info{
        display: block;
        text-align: center;
    }

    .main-menu-container{

        float: none;

    }
    
    .navbar-default {

        .navbar-toggle {
            margin-top: 12px;
            margin-bottom: 12px;
            float: none;
            .round(0px);
            span.icon-bar{
                color: @primary-color;
            }
        }

        .nav > li > a,
        header.one-page .navbar-nav > li > a {
            padding: 5px 15px;
        }
        
        .navbar-nav > li > a.link-active {
        background: none;
        }

        .navbar .navbar-right {
            width: 100%;
            text-align: left;
        }

        .navbar-collapse{
            background: #0d0d0d;
            padding: 15px 0;
            border: 0;
        }
        
        .pull-left{
            float: none !important;
            overflow: hidden;
        }
        
        .pull-right{
            float: none !important;
        }

        .navbar-nav li ul.drop-down,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 {
            background: transparent;
            border: none;
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            position: relative;
            width: auto;
            visibility: visible;
            opacity: 1;
            top: inherit;
            left: inherit;
        }

        .navbar-nav li ul.drop-down li a,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 li a {
            background: none;
            color: @light-text-color;
            font-size: 16px;
            text-transform: inherit;
            padding: 4px 20px;
        }

        .navbar-nav li ul.drop-down li a:hover,
            .navbar-nav li ul.drop-down li ul.drop-down.level3 li a:hover {
            color: @light-text-color;
        }

        .navbar-nav{

            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{

                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
        }

    }
    
    .logo{
        text-align: center;
    }
    
    .main-header {
        background: @dark-bg;
        position: relative;
    }

    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }
    

    .stuck{
        position: initial;
    }    
  
    

    .section-content-block{
        padding: 60px 0;
    }

    .section-heading{
        font-size: 32px;
        line-height: 36px;
        font-weight: normal;
        margin-bottom: 32px;

        &:before {
            top: 42px;
        }
    }

    .section-subheading{
        display:none;
    }
    
   .section-light-heading {
        font-size: 45px;
   }
   
   
    
    // PAGE HEADER
    
    .page-header{
    
        padding: 50px 0;
        
    h3{
     text-align: center;
    }
    
    .page-breadcrumb{
        text-align: center;
    }
    
    }     

    // HOME#1 SLIDER

    #slider_1{
        
        .slider_item_container{
            min-height: 350px;
        }

        .slider-content {

            text-align: center;
            top: 50%;
            transform: translateY(-55%);
            
            h2 {
               
                 font-size: 24px;
                 line-height: 36px;
                 padding-bottom:0px;
                 margin-bottom:16px;

                 &:after{
                     position:absolute;
                     content:"";
                     background:@primary-color;
                     width:100px;
                     height:2px;
                     left: 2px;
                     bottom: -8px;
                 }

             }

             h3 {
                 font-size: 16px;
                 line-height: 24px;
             }

             a.banner-video-icon {
                 border: 2px solid #f2f2f2;
                 color: @primary-color;
                 width: 60px;     
                 height: 60px;
                 font-size: 24px;
                 line-height: 55px;
                 margin: 0 auto 12px;

                 >i{
                     margin-left:6px; 
                 }

                 &:hover{
                     border: 2px solid @primary-color;

                 }
             }
            
            .slider-button{
             margin-top: 16px;  

            a.btn-slider {
                font-size: 16px;
                height: 42px;
                line-height: 24px;
                padding-left: 12px;
                padding-right: 12px;

            }
            
            a.btn-slider-2 {
                font-size: 16px;
                margin-left:10px;
                height: 42px;
                line-height: 24px;
                padding-left: 12px;
                padding-right: 12px
                
              } 
              
            }



        }

    }

    .section-banner{
        
        padding:72px 0px 48px;  
        
        .banner-content{
            transform: translateY(-30px);  
            h2 {
                font-size: 36px;
                line-height: 42px;
            }
            
            h2.offer-message {
                font-family: @slider-font;
                font-size: 36px;
                line-height: 42px;
                font-weight: 700;
                margin: 0px 0 0;
                text-transform:uppercase;
                color: @light-text-color;

                span{
                    padding: 0 3px;
                    font-weight:700;
                    color: @primary-color;
                }

            }
            h3 {
                font-size: 20px;
                display:none;
            }        

        } 

    }

    // Process Block.
    
    .process-step-1{  
        position:relative;

        h3{
            font-size:16px;
            line-height: 22px;
            margin-top:42px;
            font-weight: 500;
        }

        >span{
            display: none;
        }

    }


   // CTA
   
   .section-cta{
       .cta-content{
           h2{
               font-size:28px;
               line-height:32px;
             }
           h4{
               font-size:18px;
               span{
                   font-size:22px;
               }
           }
       }
   }

    // Appointment Form.

    .appointment-form-wrapper{
        margin: 48px 0;
        padding: 0px 5px 28px;

        h3{
            font-weight:600;
            margin:0 -5px 32px;
            position:relative;                   

        }

    }
    .appoinment-form {
        padding: 0 0px;
    }

    // SECTION COUNTER.

    .section-counter{
        padding: 48px 0;
    }
    
    

  // SECTION COUNTER.

   .section-appointment{
       
     .counter-box{
       margin-top:0;
      }       
       .counter-block-1{
        text-align:center;
       }

    }

    
    
    // POLITICAL COUNTDOWN SECTION

    .bcfix-countdown-section{

        #bcfix_election_countdown{
            
            font-size: 24px;

            .countdown_container{
                span.count_number{
                    font-size: 50px;
                    line-height: 40px;
                }
                span.count_text{
                    font-size: 18px;
                    line-height: 42px;
                }

            }

        }

        h2{
            font-size: 24px;
        }
    }     


    // Blog

    .section-home-blog{
        .latest-news-container{
            margin-bottom:30px;
        }
    }



    // Section Faq

    .section-faq .faq-box .panel-heading [data-toggle="collapse"]::after{
        display:none;  
    }
    .section-faq .faq-box .panel-body{
        padding-left:0;  
    }     


    // 404 PAGE.

    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }

    .article-container-fix,
    .main-sidebar {
        width: 100%;
        margin-left: 0px;
        border: 0px solid transparent;
    }

    .post-details-container{
        h3 {

            margin-top: 12px;

        }
    }

    .post-box-container {

        .bd-post-image-container{

            margin: 0 auto;

        }

    }

    .image_gallery_carousel{

        margin-bottom: 48px;
    }

    // Campaigns.

    a.btn-load-more{
        margin-bottom: -24px;
        margin-top: -16px;
    }


    .footer-nav{
        float: none;
        width: 100%;
        margin: 10px auto;
        display: inline-block;
        text-align: center;
    }

    .copyright-text{
        text-align: center;
    }

    //event home 2   


    .footer-widget-area {

        padding: 42px 0 0 0;

    }

    .footer-widget{
        margin-bottom: 36px;
    }

    .footer-intro{
        display: none;
    }

    .footer-nav{
        display: none;
    }

    .copyright-text{
        text-align: center;
    }


}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */

@media only screen and (min-width: 0px) and (max-width: 479px) {
    
    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }

    .top-bar{
        text-align: center;
    }
    
    .top-location-info{
        display: block;
        text-align: center;
    }
    
    .main-menu-container{

        float: none;

    }
    
     a.logo{
        text-align: center;
        width: 75%;
    }

    .navbar-default {
        
        .navbar-toggle{

            span.icon-bar{
                height: 1px;
            }
        }

        .navbar-nav{
            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                margin-left: 0px;
                
                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{

                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
        }

    }

    .logo{
        text-align: center;
    }

    .section-heading-wrapper{
        margin-bottom: 0px;
    }

    .section-content-block{
        padding: 32px 0;
    }

    .section-heading{
        font-size: 32px;
        line-height: 36px;
        font-weight: normal;
        margin-bottom: 24px;

        &:after,
            &:before{
            display: none;
        }
    }

    .section-subheading{
        display: none;
    }


    // Page Header.

    .page-header{
       padding: 80px 0;        
        h3{
            font-size: 24px;
            line-height: 32px;
            margin: 12px 0;
        }
    }    
       
    

    // Home 1 Slider

    #slider_1{
        
        .slider_item_container{
            min-height: 300px;
        }

        .slider-content {
            top: 50%;
            transform: translateY(-55%);            
        
          .slider-bg {
              background:none;
              padding:20px 15px;
              display:inline-block
             }             

             h2 {
               
                 font-size: 24px;
                 line-height: 36px;
                 padding-bottom:0px;
                 margin-bottom:16px;

                 &:after{
                     position:absolute;
                     content:"";
                     background:@primary-color;
                     width:100px;
                     height:2px;
                     left: 2px;
                     bottom: -8px;
                 }

             }

             h3 {
                 font-size: 16px;
                 line-height: 24px;
             }

             a.banner-video-icon {
                 border: 2px solid #f2f2f2;
                 color: @primary-color;
                 width: 60px;     
                 height: 60px;
                 font-size: 24px;
                 line-height: 55px;
                 margin: 0 auto 12px;

                 >i{
                     margin-left:6px; 
                 }

                 &:hover{
                     border: 2px solid @primary-color;

                 }
             }  
           
            p{
                display: none;
            }
            
            .slider-button{
             margin-top: 16px;  

            a.btn-slider {
                font-size: 14px;
                display:inline-block;
                margin-top:10px;
                height: 42px;
                line-height: 24px;
                padding-left: 10px;
                padding-right: 10px; 
                margin-right:5px;
            }
            
            a.btn-slider-2 {
                font-size: 14px;
                display:inline-block;
                margin-top:10px;                
                margin-left:0px;
                height: 42px;
                line-height: 24px;
                padding-left: 10px;
                padding-right: 10px
                
              } 
              
            }

        }


    }
    
    // Banner.
    
    .section-banner{
        
        padding:72px 0px 48px;  
        
        .banner-content{
            transform: translateY(-30px);  
            h2 {
                font-size: 36px;
                line-height: 42px;
            }
            
            h2.offer-message {
                font-family: @slider-font;
                font-size: 36px;
                line-height: 42px;
                font-weight: 700;
                margin: 0px 0 0;
                text-transform:uppercase;
                color: @light-text-color;

                span{
                    padding: 0 3px;
                    font-weight:700;
                    color: @primary-color;
                }

            }
            h3 {
                font-size: 20px;
                display:none;
            }        

        } 

    }
    
    // Process Block.
    
    .section-process{
        position: relative;
        overflow: hidden;
    }   
    
    
    .process-step-1{  
        position:relative;

        h3{
            font-size:16px;
             line-height: 22px;
            margin-top:42px;
            font-weight: 500;
        }
        
        >span{
           display: none;
        }

    }
    


    // Service Block.

    .section-how-we-work{

        .service-block{
            margin: 30px 0 0 36px;

            a.service_read_more{

                &:after{
                    display: none;

                }
            }

        }

    }

    // Appointment Form.

    .appointment-form-wrapper{
        margin: 32px 0;
        padding: 0px 5px 28px;

        h3{
            font-weight:600;
            margin:0 -5px 32px;
            position:relative;                   

        }

    }
    .appoinment-form {
        padding: 0 0px;
    }
    
    

    //Testimonial.

    .section-client-testimonial{

        .testimonial-container{ 
            padding:24px 0px;
        }


        .testimony-layout-1{

            position: relative;
            margin: 35px 0 0 0;

            .people-quote{
                font-weight:500;
                margin-top:12px;
                margin-bottom:0px;
            }

            p.testimony-text{
                font-size: 16px;
            }

        }

    } 
    
    
    .filterable-gallery{

        clear: both;

        .filter-items{
            text-align: center;
            margin-bottom: 24px;

            button{
                    margin-bottom: 6px;
            }
        }

    }
    
    

    // News Block.

    .section-home-blog{
        background: @second-bg-color;

        .latest-news-container{

            border: 0px solid @light-border-color;

            .news-content{

                padding: 12px 16px 24px;

            }

        }

    }

    // Section Faq

    .section-faq .faq-box .panel-heading [data-toggle="collapse"]::after{
        display:none;  
    }
    .section-faq .faq-box .panel-body{
        padding-left:0;  
    } 
    
     // COUNTDOWN SECTION

    .bcfix-countdown-section{

        #bcfix_election_countdown{
            
            font-size: 24px;

            .countdown_container{
                span.count_number{
                    font-size: 50px;
                    line-height: 40px;
                }
                span.count_text{
                    font-size: 18px;
                    line-height: 42px;
                }

            }

        }

        h2{
            font-size: 24px;
        }
    }   
        
    
    // Section Team.
    
    .team-layout-1 {
        clear: both;
        .team-info {
            h3{
                font-size: 16px;
                line-height: 18px;
            }
        }

        .team-social-share{
            padding: 12px;
            bottom: -100px;
            a {
                border: 1px solid #ffffff;
                color: #ffffff;
                display: inline-block;
                font-size: 12px;
                height: 20px;
                line-height: 16px;
                margin-right: 6px;
                margin-bottom: 6px;
                text-align: center;
                transform: rotate(45deg);
                width: 20px;
            }

        }
    }
    

    //Section Counter.

    .section-counter{
        padding: 32px 0 0;
        
    }

    .counter-block-1{
        margin: 12px 0 32px 0;
    }

    .counter-block-2 {
        margin: 12px 0 48px;
    }
    
    // Client Logo.

    .logo-layout-1,
    .logo-layout-2{

        .logo{

            margin-top: 0px;

        }

    }


    // 404 PAGE.

    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }

    // Footer.

    .about-footer{
        margin-bottom: 32px;
        padding-bottom: 22px;
    }

    .footer-widget-area {

        padding: 42px 0 0 0;

        .textwidget {

            i.fa{
              
            }

            p {


            }
        }

    }

    .footer-widget{
        margin-bottom: 36px;

        .footer-useful-links{
            clear: both;

            li{
                width: 100%;
                float: none;
            }
        }
    }

    .footer-intro{
        display: none;
    }

    .footer-nav{
        display: none;
    }

    .copyright-text{
        text-align: center;
    }

}

/* Tab Portrait Size to Tab Landscape Size (devices and browsers) */
@media only screen and (min-width:380px) and (max-width: 480px)  {
    .xs-margin{
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (min-width:480px) and (max-width: 600px)  {
    .xs-margin{
        margin-left: 70px;
        margin-right: 70px;
    }
}

@media only screen and (min-width:600px) and (max-width: 767px)  {
    .xs-margin{
        margin-left: 120px;
        margin-right: 120px;
    }
}